import { template } from "@ember/template-compiler";
import { concat } from '@ember/helper';
import { modifier } from 'ember-modifier';
import { waitForLater } from 'ember-simplepractice/utils/waiters';
import Component from '@glimmer/component';
import styles from './permissions-modal.module.scss';
export default class PermissionsModal extends Component {
    delayDuration = 1000;
    get isFirefox() {
        return /Firefox|FxiOS/.test(window.navigator.userAgent);
    }
    get isEdge() {
        return /(Edge\/[\d.]+|Trident\/8)/.test(window.navigator.userAgent);
    }
    onSetup = modifier(async (element1)=>{
        await waitForLater(this.delayDuration);
        element1.parentNode.style.opacity = '1';
    });
    static{
        template(`
    <div
      class={{concat
        "permissions-container "
        styles.component
        (if this.isFirefox " firefox")
        (if this.isEdge " edge")
      }}
    >
      <div {{this.onSetup}} data-test-permissions-error>
        <div class="content permissions-required">
          <div class="heading">
            <span>Please click &lsquo;Allow&rsquo; to enable access to your webcam and microphone.</span>
          </div>
          <div class="help-text">
            <span>Don’t see this?</span>
            <a href="">Reload the page</a>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
