import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { svgJar } from 'ember-svg-jar/helpers/svg-jar';
import SharedModal from 'frontend/components/shared/modal';
import styles from './participants-limit.module.scss';
export default template(`
  <div class={{styles.component}} data-test-participants-limit-error>
    <SharedModal @close={{@close}} @class="participant-limit-modal">
      {{svgJar "participants-limit" class="icon"}}
      <p class="modal-title" data-test-participants-limit-error-title>
        This appointment is at capacity
      </p>
      <p class="modal-sub-title">
        This appointment has reached the maximum number of participants. Please contact your
        provider or try again.
      </p>
      <button class="button primary" type="button" {{on "click" @close}}>OK</button>
    </SharedModal>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
