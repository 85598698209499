import { DEFAULT_ZOOM_COEFFICIENT, TEN_SECONDS_MS, VIDEO_QUALITY_TYPES } from 'frontend/constants';
import { MEETING_EVENTS } from 'frontend/services/chime/event-manager';
import { PROCESSOR_TYPES, VIRTUAL_BACKGROUNDS_MAP } from 'frontend/constants/backgrounds';
import { QUALITY_OPTIONS } from 'frontend/constants/chime';
import { action } from '@ember/object';
import { confirmation } from 'frontend/utils/modals';
import { findDeviceById } from 'frontend/utils/devices';
import { importAsset } from 'frontend/helpers/import-asset';
import { or, reads } from 'macro-decorators';
import { task, timeout } from 'ember-concurrency';
import { tracked } from 'tracked-built-ins';
import CropVideoChimeProcessor from 'frontend/utils/processors/crop-video-chime';
import DevicesTimeoutError from 'frontend/entities/devices-timeout-error';
import Service, { service } from '@ember/service';
import cancelSafeRace from 'frontend/utils/cancel-safe-race';

export default class ChimeLocalAudioVideoService extends Service {
  @service('chime.meeting-manager') meetingManager;
  @service('chime.sdk') sdkService;
  @service persistentProperties;
  @service errorHandling;
  @service remoteLogger;
  @service mediaDevices;

  @tracked tileId = null;
  @tracked audioInputDevices;
  @tracked audioOutputDevices;
  @tracked videoInputDevices;
  @tracked selectedAudioInputDevice;
  @tracked selectedAudioOutputDevice;
  @tracked selectedVideoInputDevice;
  @tracked videoInput;
  @tracked isVoiceFocusSupported;
  @tracked isVideoProcessingSupported;

  voiceFocusDevice = null;
  audioVideoObserver;
  deviceChangeObserver;
  devicesTimeoutMs = TEN_SECONDS_MS;

  @reads('persistentProperties.selectedAudioInputDeviceId') preferredAudioInputDevice;
  @reads('persistentProperties.selectedAudioOutputDeviceId') preferredAudioOutputDevice;
  @reads('persistentProperties.selectedVideoDeviceId') preferredVideoInputDevice;
  @reads('persistentProperties.selectedVideoQuality') selectedVideoQuality;
  @reads('persistentProperties.selectedVideoProcessorType') selectedVideoProcessorType;
  @reads('persistentProperties.noiseCancellationEnabled') noiseCancellationEnabled;
  @reads('persistentProperties.isAudioPublished') isAudioPublished;
  @reads('persistentProperties.isVideoPublished') isVideoPublished;
  @reads('meetingManager.audioVideo') audioVideo;
  @reads('meetingManager.isWebAudioEnabled') isWebAudioEnabled;
  @reads('sdkService.sdk') chimeSdk;
  @reads('meetingManager.meetingSession.eventController') eventController;
  @or('videoTransformDevice', 'selectedVideoInputDevice') activeVideoDevice;

  setup(config) {
    this.audioVideoObserver = {
      audioVideoDidStart: this.audioVideoDidStart,
      audioVideoDidStop: this.audioVideoDidStop,
      videoTileDidUpdate: this.videoTileDidUpdate,
      videoTileWasRemoved: this.videoTileWasRemoved,
    };
    this.eventsObserver = {
      eventDidReceive: (name, attributes) => {
        if (name !== MEETING_EVENTS.deviceLabelTriggerFailed) return;

        this.deviceLabelTriggerFailed(attributes);
      },
    };
    this.config = config;
    this.audioVideo.addObserver(this.audioVideoObserver);
    this.eventController.addObserver(this.eventsObserver);
  }

  get isRequiredDevicesListEmpty() {
    return !this.videoInputDevices.length || !this.audioInputDevices.length;
  }

  async setupDevices() {
    let { PermissionDeniedError } = this.chimeSdk;

    if (await this.mediaDevices.getPermissionDenied()) throw new PermissionDeniedError();

    this.deviceChangeObserver = {
      audioInputsChanged: this.audioInputsChanged,
      audioOutputsChanged: this.audioOutputsChanged,
      videoInputsChanged: this.videoInputsChanged,
    };
    this.isVoiceFocusSupported ??= this.isWebAudioEnabled;

    this.audioVideo.addDeviceChangeObserver(this.deviceChangeObserver);
    this.#setupDeviceLabelTrigger();
    this.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(this.onMutedStateChange);
    await this.#checkVideoProcessingSupport();
    await this.startDevices();

    if (this.isAudioPublished) return;
    this.audioVideo.realtimeMuteLocalAudio();
  }

  reset() {
    this.tileId = null;
    this.config = null;
    this.audioVideo?.removeObserver(this.audioVideoObserver);
    this.audioVideo?.removeDeviceChangeObserver(this.deviceChangeObserver);
    this.eventController?.removeObserver(this.eventsObserver);
    this.inputDeviceTimeoutTask.cancelAll();
    this.mediaDevices.cancelDeviceLabelsTrigger();
  }

  getCurrentVideoConstrains() {
    switch (this.selectedVideoQuality) {
      case VIDEO_QUALITY_TYPES.ld:
        return QUALITY_OPTIONS[VIDEO_QUALITY_TYPES.ld];
      case VIDEO_QUALITY_TYPES.sd:
        return QUALITY_OPTIONS[VIDEO_QUALITY_TYPES.sd];
      default:
        return QUALITY_OPTIONS[VIDEO_QUALITY_TYPES.hd];
    }
  }

  async startDevices() {
    if (!this.isStartedDevices) {
      await this.updateDeviceLists();
      this.updateSelectedDevices();
      await this.handleVideoInputChange();
    }
    await this.handleAudioInputChange();
    await this.handleAudioOutputChange();

    this.isStartedDevices = true;
  }

  inputDeviceTimeoutTask = task(async options => {
    let { timeoutMs = this.devicesTimeoutMs, deviceType } = options;
    await timeout(timeoutMs);
    throw new DevicesTimeoutError(`Starting ${deviceType} timeout`);
  });

  async updateDeviceLists() {
    let audioInputDevices = await this.audioVideo?.listAudioInputDevices();
    let audioOutputDevices = await this.audioVideo?.listAudioOutputDevices();
    let videoInputDevices = await this.audioVideo?.listVideoInputDevices();

    this.audioInputDevices = audioInputDevices || [];
    this.audioOutputDevices = audioOutputDevices || [];
    this.videoInputDevices = videoInputDevices || [];
  }

  updateSelectedDevices() {
    this.selectedAudioInputDevice ||= findDeviceById(
      this.audioInputDevices,
      this.preferredAudioInputDevice
    );
    this.selectedAudioOutputDevice ||= findDeviceById(
      this.audioOutputDevices,
      this.preferredAudioOutputDevice
    );
    this.selectedVideoInputDevice ||= findDeviceById(
      this.videoInputDevices,
      this.preferredVideoInputDevice
    );
  }

  updatePreferredAudioInputDevice(deviceId) {
    this.persistentProperties.setProp('selectedAudioInputDeviceId', deviceId);
    this.selectedAudioInputDevice = deviceId;
    this.handleAudioInputChange();
  }

  updatePreferredVideoInputDevice(deviceId) {
    this.persistentProperties.setProp('selectedVideoDeviceId', deviceId);
    this.selectedVideoInputDevice = deviceId;
    this.handleVideoInputChange();
  }

  updatePreferredAudioOutputDevice(deviceId) {
    this.persistentProperties.setProp('selectedAudioOutputDeviceId', deviceId);
    this.selectedAudioOutputDevice = deviceId;
    this.handleAudioOutputChange();
  }

  async handleAudioInputChange() {
    if (!this.selectedAudioInputDevice) return;

    return this.startAudioInput();
  }

  async startAudioInput() {
    let device = this.selectedAudioInputDevice;

    if (this.useNoiseCancellation) {
      let voiceFocusDevice = await this.createOrUpdateVoiceFocusDevice();
      if (voiceFocusDevice) await this.#verifyUserActivation();

      device = voiceFocusDevice || device;
    }

    if (!this.audioVideo) return;

    try {
      await cancelSafeRace([
        this.inputDeviceTimeoutTask.perform({ deviceType: 'Audio input' }),
        this.audioVideo.startAudioInput(device),
      ]);
    } catch (err) {
      await this.#handleDevicesTimeoutError(err, {
        constraints: { audio: true },
        deviceId: device,
        retryWithStream: stream => this.audioVideo.startAudioInput(stream),
      });
    }
  }

  async #verifyUserActivation() {
    if (navigator.userActivation?.hasBeenActive || this.#isAudioContextActivated()) return;

    return confirmation({
      title: 'We need your permission',
      text: 'To continue, please allow access to your Audio Input.',
      confirmButtonText: 'Allow',
      showCloseButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
    });
  }

  #isAudioContextActivated() {
    let audioContext = new AudioContext();
    return audioContext.state !== 'suspended';
  }

  async createOrUpdateVoiceFocusDevice() {
    if (!this.voiceFocusDevice) {
      return await this.createVoiceFocusDevice();
    }

    if (this.selectedAudioInputDevice !== this.voiceFocusDevice.device) {
      await this.updateVoiceFocusDevice();
    }
    return this.voiceFocusDevice;
  }

  async createVoiceFocusDevice() {
    let { VoiceFocusDeviceTransformer } = this.chimeSdk;

    try {
      let transformer = await VoiceFocusDeviceTransformer.create();

      this.isVoiceFocusSupported = transformer.isSupported();
      if (!this.isVoiceFocusSupported) {
        return;
      }

      this.voiceFocusDevice = await transformer.createTransformDevice(
        this.selectedAudioInputDevice
      );
      return this.voiceFocusDevice;
    } catch (e) {
      this.isVoiceFocusSupported = false;
      this.remoteLogger.info('VoiceFocus device creation failed');
    }
  }

  async updateVoiceFocusDevice() {
    this.voiceFocusDevice = await this.voiceFocusDevice.chooseNewInnerDevice(
      this.selectedAudioInputDevice
    );
  }

  get useNoiseCancellation() {
    return this.noiseCancellationEnabled && this.isVoiceFocusSupported;
  }

  async handleAudioOutputChange() {
    let { DefaultBrowserBehavior } = this.chimeSdk;

    if (!this.audioVideo || !new DefaultBrowserBehavior().supportsSetSinkId()) return;

    return this.audioVideo.chooseAudioOutput(this.selectedAudioOutputDevice);
  }

  async handleVideoInputChange() {
    let handleVideoInput = async () => {
      if (this.videoInput) {
        await this.videoTransformDevice?.stop();
        await this.stopVideoInput();
        this.videoFxProcessor = null;
      }

      this.selectVideoQuality(this.selectedVideoQuality);
      if (!this.isVideoPublished || !this.selectedVideoInputDevice) return;
      await this.setVideoProcessor(this.selectedVideoProcessorType);
      await this.startVideoInputDevice();
    };

    try {
      await cancelSafeRace([
        this.inputDeviceTimeoutTask.perform({ deviceType: 'Video input' }),
        handleVideoInput(),
      ]);
    } catch (err) {
      await this.#handleDevicesTimeoutError(err, {
        constraints: { video: true },
        deviceId: this.selectedVideoInputDevice,
        retryWithStream: stream => this.startVideoInputDevice(stream),
      });
    }
  }

  async toggleVideo() {
    if (this.isVideoPublished) {
      await this.stopVideoInput();
    } else {
      await this.startVideoInputDevice();
    }
    this.persistentProperties.setProp('isVideoPublished', !this.isVideoPublished);
  }

  async startVideoInputDevice(stream = null) {
    this.videoInput = await this.startLocalVideo(stream);
    if (!this.meetingManager.isConnected) return;
    this.audioVideo?.startLocalVideoTile();
  }

  async startLocalVideo(stream = null) {
    return this.audioVideo?.startVideoInput(stream || this.activeVideoDevice);
  }

  async stopVideoInput() {
    if (this.meetingManager.isConnected) this.audioVideo?.stopLocalVideoTile();

    await this.audioVideo?.stopVideoInput();
    this.audioVideo?.removeLocalVideoTile();
    this.videoInput = null;
  }

  async stopDevices() {
    if (!this.isStartedDevices) return;

    this.isStartedDevices = false;

    if (this.selectedAudioOutputDevice) {
      await this.audioVideo?.chooseAudioOutput(null);
    }
    await this.audioVideo?.stopAudioInput();
    await this.stopVideoInput();
  }

  selectVideoQuality(quality = VIDEO_QUALITY_TYPES.hd) {
    if (!this.audioVideo) return;

    if (quality === VIDEO_QUALITY_TYPES.auto || !QUALITY_OPTIONS[quality]) {
      quality = VIDEO_QUALITY_TYPES.hd; // todo: process auto quality
    }
    let { width, height, frameRate, maxBandwidth } = QUALITY_OPTIONS[quality];

    this.audioVideo.chooseVideoInputQuality(width, height, frameRate);
    this.audioVideo.setVideoMaxBandwidthKbps(maxBandwidth);
  }

  @action
  videoTileDidUpdate(tileState) {
    if (
      !this.audioVideo ||
      !tileState.localTile ||
      !tileState.tileId ||
      this.tileId === tileState.tileId
    )
      return;

    this.tileId = tileState.tileId;
  }

  @action
  videoTileWasRemoved(tileId) {
    if (tileId !== this.tileId) return;

    this.tileId = null;
  }

  @action
  bindLocalVideo(videoElement) {
    this.audioVideo.bindVideoElement(this.tileId, videoElement);
  }

  @action
  onMutedStateChange(localMuted) {
    this.persistentProperties.setProp('isAudioPublished', !localMuted);
  }

  @action
  audioVideoDidStart() {
    if (!this.videoInput) return;

    this.audioVideo?.startLocalVideoTile();
  }

  @action
  audioVideoDidStop() {
    this.tileId = null;
  }

  @action
  deviceLabelTriggerFailed({ deviceLabelTriggerErrorMessage }) {
    let { GetUserMediaError } = this.chimeSdk;

    this.errorHandling.getDevicesError(new GetUserMediaError(deviceLabelTriggerErrorMessage));
    this.config?.onDeviceLabelTriggerFail?.();
  }

  @action
  audioInputsChanged(freshAudioInputDeviceList) {
    this.audioInputDevices = freshAudioInputDeviceList;
    let shouldUpdateDevice = this.shouldUpdateSelectedDevice(
      this.audioInputDevices,
      this.selectedAudioInputDevice
    );
    if (!shouldUpdateDevice) return;

    this.selectedAudioInputDevice = freshAudioInputDeviceList[0]?.deviceId;
    this.handleAudioInputChange();
  }

  @action
  audioOutputsChanged(freshAudioOutputDeviceList) {
    this.audioOutputDevices = freshAudioOutputDeviceList;
    let shouldUpdateDevice = this.shouldUpdateSelectedDevice(
      this.audioOutputDevices,
      this.selectedAudioOutputDevice
    );

    if (!shouldUpdateDevice) return;

    this.selectedAudioOutputDevice = freshAudioOutputDeviceList[0]?.deviceId;
    this.handleAudioOutputChange();
  }

  @action
  videoInputsChanged(freshVideoInputDeviceList) {
    this.videoInputDevices = freshVideoInputDeviceList;
    let shouldUpdateDevice = this.shouldUpdateSelectedDevice(
      this.videoInputDevices,
      this.selectedVideoInputDevice
    );

    if (!shouldUpdateDevice) return;

    this.selectedVideoInputDevice = freshVideoInputDeviceList[0]?.deviceId;
    this.handleVideoInputChange();
  }

  shouldUpdateSelectedDevice(devices, selectedDeviceId) {
    if (selectedDeviceId === 'default') return true;

    let isDeviceSelected = !!selectedDeviceId;
    let isDeviceAvailable = devices.length > 0;
    let isSelectedDeviceAvailable = devices.some(device => device.deviceId === selectedDeviceId);

    return (!isDeviceSelected && isDeviceAvailable) || !isSelectedDeviceAvailable;
  }

  async setVideoProcessor(processorType) {
    let { DefaultVideoTransformDevice, CanvasVideoFrameBuffer } = this.chimeSdk;

    if (!processorType) return;

    switch (processorType) {
      case PROCESSOR_TYPES.none:
        this.videoFxProcessor = null;
        this.videoTransformDevice?.stop();
        this.videoTransformDevice = null;
        break;
      case PROCESSOR_TYPES.crop:
        this.videoFxProcessor = null;
        this.videoTransformDevice = new DefaultVideoTransformDevice(
          this.meetingManager.logger,
          this.selectedVideoInputDevice,
          [new CropVideoChimeProcessor(DEFAULT_ZOOM_COEFFICIENT, CanvasVideoFrameBuffer)]
        );
        break;
      default:
        await this.#processVideoFxProcessor(processorType);
    }
  }

  async updateVideoProcessor(newProcessorType) {
    let processorType = newProcessorType || PROCESSOR_TYPES.none;

    await this.setVideoProcessor(processorType);
    this.persistentProperties.setProp('selectedVideoProcessorType', processorType);

    if (!this.videoInput) return;
    await this.startVideoInputDevice();
  }

  getVideoFxConfig(processorType) {
    let videoFxConfig = {
      backgroundBlur: { isEnabled: false, strength: 'medium' },
      backgroundReplacement: { isEnabled: false, backgroundImageURL: null, defaultColor: null },
    };

    switch (processorType) {
      case PROCESSOR_TYPES.blur:
        videoFxConfig.backgroundBlur.isEnabled = true;
        break;
      default: {
        if (!VIRTUAL_BACKGROUNDS_MAP[processorType]) break;

        let srcKey =
          this.selectedVideoQuality === VIDEO_QUALITY_TYPES.hd
            ? VIDEO_QUALITY_TYPES.hd
            : VIDEO_QUALITY_TYPES.sd;
        let importedImage = importAsset([VIRTUAL_BACKGROUNDS_MAP[processorType][srcKey]]);

        videoFxConfig.backgroundReplacement.isEnabled = true;
        videoFxConfig.backgroundReplacement.backgroundImageURL = importedImage;
      }
    }

    return videoFxConfig;
  }

  async #checkVideoProcessingSupport() {
    let { VideoFxProcessor } = this.chimeSdk;

    this.isVideoProcessingSupported ??= await VideoFxProcessor.isSupported();
  }

  async #processVideoFxProcessor(processorType) {
    let { VideoFxProcessor, DefaultVideoTransformDevice } = this.chimeSdk;
    let videoFxConfig = this.getVideoFxConfig(processorType);
    let errorMessage = 'Video processing is not supported';

    this.remoteLogger.info('Setting video processor');

    if (!this.isVideoProcessingSupported) return this.errorHandling.notifyError(errorMessage);

    if (this.videoFxProcessor) return this.videoFxProcessor.setEffectConfig(videoFxConfig);

    try {
      this.videoFxProcessor = await VideoFxProcessor.create(
        this.meetingManager.logger,
        videoFxConfig
      );
      this.videoTransformDevice = new DefaultVideoTransformDevice(
        this.meetingManager.logger,
        this.selectedVideoInputDevice,
        [this.videoFxProcessor]
      );
    } catch (cause) {
      return this.errorHandling.notifyError(errorMessage, { cause });
    }
  }

  #setupDeviceLabelTrigger() {
    let { DefaultBrowserBehavior } = this.chimeSdk;

    if (new DefaultBrowserBehavior().doesNotSupportMediaDeviceLabels()) return;

    this.audioVideo.setDeviceLabelTrigger(() =>
      this.mediaDevices.triggerDeviceLabels(this.getCurrentVideoConstrains())
    );
  }

  async #handleDevicesTimeoutError(err, { constraints, deviceId, retryWithStream } = {}) {
    if (!(err instanceof DevicesTimeoutError)) throw err;

    let stream = await cancelSafeRace([
      this.inputDeviceTimeoutTask.perform({ timeoutMs: this.devicesTimeoutMs / 2 }),
      this.mediaDevices.getUserMedia(constraints),
    ]).catch(() => null);

    if (!stream) {
      err.cause = { couldStartDevice: false };
      throw err;
    }

    let settings = stream.getTracks()[0]?.getSettings();
    let withSameDeviceId = settings?.deviceId === deviceId;

    if (!withSameDeviceId) {
      err.cause = { couldStartDevice: true, withSameDeviceId };
      throw err;
    }

    return retryWithStream(stream);
  }
}
