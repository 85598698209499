/* import __COLOCATED_TEMPLATE__ from './waiting-placeholder.hbs'; */
import { modifier } from 'ember-modifier';
import { notEmpty, reads } from 'macro-decorators';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './waiting-placeholder.module.scss';

export default class WaitingPlaceholder extends Component {
  @service('twilio/room') room;
  @service('chime.meeting-manager') meetingManager;
  @service session;
  @service mixpanel;
  @service call;
  @service roomChannel;

  styles = styles;

  @reads('call.isConnected') isConnected;
  @reads('session.inWaitingRoom') inWaitingRoom;
  @reads('session.isHost') isHost;
  @reads('session.joinRequestDeclined') joinRequestDeclined;
  @reads('session.userData') userData;
  @reads('session.isClientOrigin') isClientOrigin;
  @reads('session.signInUrl') signInUrl;
  @reads('session.switchAccountUrl') switchAccountUrl;
  @reads('session.roomModel') roomModel;
  @reads('userData.clinicianName') clinicianName;
  @reads('userData.practiceName') practiceName;
  @reads('roomModel.clinicianFullName') hostClinicianName;
  @reads('roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @reads('roomModel.featureThEnsureTracks') featureThEnsureTracks;
  @reads('roomModel.featureThChime') featureThChime;
  @reads('room.localTracks.hasConfirmedTrack') hasConfirmedTrack;
  @reads('meetingManager.isLoading') isChimeLoading;
  @reads('roomChannel.isConnecting') isRoomChannelConnecting;
  @notEmpty('userData.id') isClinician;

  get isJoiningTransitionState() {
    if (!this.featureThClinicianAuth || this.isHost || this.inWaitingRoom) return false;

    if (this.featureThChime) return this.isChimeLoading;

    if (!this.isConnected) return true;

    return this.isFakeJoiningTransitionShown;
  }

  get isFakeJoiningTransitionShown() {
    if (!this.featureThEnsureTracks) return false;

    return !this.hasConfirmedTrack;
  }

  setupTrackLinks = modifier(element => {
    this.mixpanel.trackLinks(element, 'telehealth: clinician sign in', {
      'switch_accounts': element.href.includes(this.switchAccountUrl),
      ...this.mixpanel.eventPayload,
    });
  });
}
