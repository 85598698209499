/* import __COLOCATED_TEMPLATE__ from './text-editor.hbs'; */
import {
  BULLET_SYMBOL,
  FIXED_WIDTH,
  TEXT_VIRTUAL_PADDING,
  TOOLTIP_SHOW_COUNT_CAP,
} from 'frontend/constants/whiteboard';
import { KEY_CODES } from 'frontend/constants';
import { action, setProperties } from '@ember/object';
import { addMacroBullet, addNewLineBullet, countBullets } from 'frontend/utils/text';
import { htmlSafe } from '@ember/template';
import { lt, reads } from 'macro-decorators';
import { range } from 'frontend/utils/number-array';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { waitForNext, waitForRender } from 'ember-simplepractice/utils/waiters';
import Component from '@glimmer/component';
import styles from './text-editor.module.scss';

export default class WhiteboardTextEditor extends Component {
  @service persistentProperties;

  @tracked dataValue = '';
  @tracked textWidth = 'auto';
  @tracked textHeight = 'auto';

  componentClassName = styles.component;

  @reads('args.activeObject') activeObject;
  @reads('activeObject.konvaObject') textNode;
  @reads('persistentProperties.wbTypeTooltipShownCount') wbTypeTooltipShownCount;
  @lt('wbTypeTooltipShownCount', TOOLTIP_SHOW_COUNT_CAP) showTooltip;

  onTextChanged = () => {
    let textContent = this.dataValue.trim();

    if (textContent) {
      let options = {
        visible: true,
        isLocked: false,
        ...this.additionalTextOptions(),
      };
      this.args.handleTextChange(this.activeObject, textContent, options);
    } else {
      this.args.deleteObject(this.activeObject);
    }
  };

  additionalTextOptions() {
    let { x } = this.textNode.getAbsolutePosition();
    let { x: scale } = this.textNode.getAbsoluteScale();
    let canvasWidth = this.stage.width();
    let occupiedWidth = x + this.textWidth + TEXT_VIRTUAL_PADDING;

    if (occupiedWidth > canvasWidth) {
      return { width: (canvasWidth - x) / scale };
    }
  }

  get stage() {
    return this.args.activeObject.konvaObject.getStage();
  }

  get textareaStyles() {
    let getValue = val => (val === 'auto' ? val : `${val}px`);
    return htmlSafe(
      `width: ${getValue(this.textWidth)};height: ${getValue(
        this.textHeight
      )};color: ${this.textNode.fill()};`
    );
  }

  get rows() {
    return this.dataValue.split('\n').length;
  }

  @action
  async initTextEditor(textarea) {
    let textPosition = this.textNode.absolutePosition();
    let { x: scale } = this.textNode.getAbsoluteScale();
    let textEditor = textarea.parentElement;
    let rotation = this.textNode.rotation();

    textarea.value = htmlSafe(this.textNode.text());
    textEditor.style.top = textPosition.y + 'px';
    textEditor.style.left = textPosition.x + 'px';
    textEditor.style.fontSize = this.textNode.fontSize() * scale + 'px';
    textEditor.style.lineHeight = this.textNode.lineHeight();
    textEditor.style.fontFamily = this.textNode.fontFamily();

    if (rotation) {
      textEditor.style.transform += 'rotateZ(' + rotation + 'deg)';
    }

    let wbTypeTooltipShownCount = isNaN(this.wbTypeTooltipShownCount)
      ? 0
      : this.wbTypeTooltipShownCount;

    this.textNode.hide();
    this.dataValue = this.textNode.text();
    this.persistentProperties.setProps({
      wbTypeTooltipShownCount: Math.min(wbTypeTooltipShownCount + 1, TOOLTIP_SHOW_COUNT_CAP),
    });
    await waitForNext();
    this.fitTextInCanvas(textarea);
  }

  @action
  async handleInputChange({ target }) {
    this._handleBulletsAdding(target);
    this.dataValue = target.value;
    await waitForRender();
    this.fitTextInCanvas(target);
  }

  fitTextInCanvas(textarea) {
    let label = textarea.parentElement;
    let pseudoElement = window.getComputedStyle(label, '::after');
    let canvasRect = this.stage.container().getBoundingClientRect();

    if (!this.dataValue.length) label.classList.remove(FIXED_WIDTH);

    this.computeAndSetTextWidth(label, canvasRect, Math.ceil(parseFloat(pseudoElement.width)));
    this.computeAndSetTextHeight(label, canvasRect, parseInt(pseudoElement.height));
  }

  computeAndSetTextWidth(label, canvasRect, pseudoElementWidth) {
    let labelRect = label.getBoundingClientRect();
    let occupiedWidth = parseInt(labelRect.x - canvasRect.x + pseudoElementWidth);
    let availableWidth = this.stage.width();

    if (occupiedWidth + TEXT_VIRTUAL_PADDING < availableWidth) {
      this.textWidth = pseudoElementWidth;
    } else {
      this.textWidth = availableWidth - (labelRect.x - canvasRect.x);
    }
    this.computePseudoBoxPosition(label, { pseudoElementWidth, availableWidth, occupiedWidth });
  }

  computeAndSetTextHeight(label, canvasRect, pseudoElementHeight) {
    let labelRect = label.getBoundingClientRect();
    let occupiedHeight = parseInt(
      labelRect.y - canvasRect.y + pseudoElementHeight + label.scrollTop
    );
    let availableHeight = this.stage.height();
    let rowHeight = label.style.lineHeight * parseInt(label.style.fontSize);

    if (occupiedHeight > availableHeight) {
      this.textHeight =
        occupiedHeight + rowHeight >= availableHeight
          ? availableHeight - (labelRect.y - canvasRect.y)
          : pseudoElementHeight;
    } else if (this.rows * rowHeight <= pseudoElementHeight) {
      this.textHeight = 'auto';
    }
  }

  computePseudoBoxPosition(label, { pseudoElementWidth, availableWidth, occupiedWidth }) {
    let textWidth = Math.ceil(this.textWidth);
    if (textWidth === pseudoElementWidth && availableWidth - occupiedWidth === 0) return;
    if (pseudoElementWidth > textWidth || pseudoElementWidth > availableWidth) {
      label.classList.add(FIXED_WIDTH);
    } else {
      label.classList.remove(FIXED_WIDTH);
    }
  }

  @action
  handleKeyDown(evt) {
    switch (evt.keyCode) {
      case KEY_CODES.backspace:
        this._handleBulletRemove(evt);
        break;
      case KEY_CODES.enter:
        this.args.handleTextChange(this.activeObject, evt.target.value, { keepSelection: true });
        break;
    }
  }

  _handleBulletRemove(evt) {
    let { value: text, selectionEnd: cursorPosition } = evt.target;
    let bulletShift = this._findCursorPositionOnBullet(text, cursorPosition);
    if (!bulletShift) return;
    evt.preventDefault();
    let bulletIndex = cursorPosition - bulletShift;
    let newText = text.substr(0, bulletIndex) + text.substr(bulletIndex + BULLET_SYMBOL.length);
    setProperties(evt.target, {
      value: newText,
      selectionEnd: bulletIndex,
    });
    this.dataValue = newText;
  }

  _findCursorPositionOnBullet(text, cursorPosition) {
    return range(1, BULLET_SYMBOL.length).reduce(
      (acc, index) =>
        text.substr(cursorPosition - index, BULLET_SYMBOL.length) === BULLET_SYMBOL ? index : acc,
      null
    );
  }

  _handleBulletsAdding(textarea) {
    let cursorPosition = textarea.selectionEnd;
    let text = textarea.value.slice(0, cursorPosition);
    let textBeforeCursor = addNewLineBullet(addMacroBullet(text));

    if (this._shouldTrackBullets(text, textBeforeCursor) && !this.activeObject.tracked) {
      this.args.trackMixpanelEvent('whiteboard tool used', { 'tool_type': 'bullets' });
      setProperties(this.activeObject, { tracked: true });
    }
    let newText = textBeforeCursor + textarea.value.slice(cursorPosition);
    setProperties(textarea, {
      value: newText,
      selectionEnd: textBeforeCursor.length,
    });

    this.dataValue = newText;
  }

  _shouldTrackBullets(text, newText) {
    let newTextBulletsCount = countBullets(newText);
    return newTextBulletsCount - countBullets(text) === 1 && newTextBulletsCount === 2;
  }
}
