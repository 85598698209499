import { Arrow, Circle, Grid, Line, Square, Triangle } from 'frontend/entities/whiteboard/shapes';
import {
  BACKGROUND_ID,
  BASE_HEIGHT,
  BASE_WIDTH,
  WHITEBOARD_COLORS,
  WHITEBOARD_ENTITIES,
  WHITEBOARD_SHAPES,
  WHITEBOARD_TOOLS,
} from 'frontend/constants/whiteboard';
import { HD_VIDEO_CONSTRAINTS, SCREEN_CONSTRAINTS } from 'frontend/constants';
import { SHAPES } from 'frontend/constants/shapes';
import {
  WhiteboardImage,
  WhiteboardLine,
  WhiteboardShape,
  WhiteboardText,
  WhiteboardVanishingLine,
} from 'frontend/entities/whiteboard';
import { action, set } from '@ember/object';
import {
  getOpacityOption,
  getStickerSVGContent,
  isOutOfStage,
  rotatePoint,
} from 'frontend/utils/whiteboard';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { loadImage } from 'frontend/utils/load-image';
import { loadPdfJsLib } from 'frontend/utils/pdf';
import { tracked } from '@glimmer/tracking';
import Action from 'frontend/entities/whiteboard/action';
import Service from '@ember/service';
import classic from 'ember-classic-decorator';
import generateUUID from 'ember-simplepractice/utils/generate-uuid';

@classic
export default class WhiteboardService extends Service {
  @tracked isLocked = true;
  @tracked localActions = [];
  @tracked activeObject = null;

  Konva;
  objects = new Map();
  videoStreamRequestFrameId = null;
  initialState = null;

  async initStage(container) {
    if (!this.Konva) {
      await this._importKonva();
    }

    return new this.Konva.Stage({ container, width: BASE_WIDTH, height: BASE_HEIGHT });
  }

  setInitialData({ state, isLocked }) {
    this.isLocked = isLocked;

    if (state === undefined) return;

    this.initialState = state;
  }

  getLayer() {
    return new this.Konva.Layer();
  }

  rotateAroundCenter(node, rotation, stage) {
    // current rotation origin (0, 0) relative to desired origin - center (node.width()/2, node.height()/2)
    let topLeft = { x: -node.width() / 2, y: -node.height() / 2 };
    let current = rotatePoint(topLeft, this.Konva.getAngle(node.rotation()));
    let rotated = rotatePoint(topLeft, this.Konva.getAngle(rotation));
    let dx = rotated.x - current.x;
    let dy = rotated.y - current.y;
    let newX = node.x() + dx;
    let newY = node.y() + dy;

    if (
      isOutOfStage(stage, {
        x: newX,
        y: newY,
        width: node.width(),
        height: node.height(),
        rotation,
      })
    ) {
      return;
    }

    node.rotation(rotation);
    node.x(newX);
    node.y(newY);
  }

  async _importKonva() {
    let [{ default: Konva }] = await Promise.all([
      import('konva/lib/Core'),
      import('konva/lib/shapes/Rect'),
      import('konva/lib/shapes/Line'),
      import('konva/lib/shapes/Text'),
      import('konva/lib/shapes/Image'),
      import('konva/lib/shapes/Transformer'),
    ]);

    set(this, 'Konva', Konva);
  }

  async getNodesOptionsList(json) {
    if (!this.Konva) {
      await this._importKonva();
    }

    let layer = this.Konva.Node.create(json);
    let children = layer.getChildren();

    return children.reduce((acc, curr) => {
      let { attrs } = curr;

      if (curr instanceof this.Konva.Line) {
        acc.push({ ...attrs, color: attrs.stroke, tool: WHITEBOARD_TOOLS.draw });
      } else if (curr instanceof this.Konva.Text) {
        acc.push({
          ...attrs,
          color: attrs.fill,
          tool: WHITEBOARD_TOOLS.text,
          points: [attrs.x, attrs.y],
        });
      } else if (curr instanceof this.Konva.Image) {
        acc.push({
          ...attrs,
          fileData: { fileBase64: attrs.src, fileId: attrs.uuid },
          tool: WHITEBOARD_TOOLS.image,
        });
      } else if (curr instanceof this.Konva.Shape) {
        acc.push({ ...attrs, tool: WHITEBOARD_TOOLS.shapes });
      }

      return acc;
    }, []);
  }

  getInitialOptionsList() {
    return this.getNodesOptionsList(this.initialState);
  }

  addBackgroundToLayer(layer) {
    let background = new this.Konva.Rect({
      fill: WHITEBOARD_COLORS.white,
      width: BASE_WIDTH,
      height: BASE_HEIGHT,
      id: BACKGROUND_ID,
    });

    layer.add(background);
  }

  cleanupWhiteboard() {
    this.objects.clear();
    this.localActions = [];
    this.setActiveObject(null);
  }

  async getBoardObject(options) {
    let { uuid = generateUUID(), fileData, previewMode, tool, x, y } = options;

    switch (tool) {
      case WHITEBOARD_TOOLS.draw:
        return this._getLineObject({ uuid, ...options });
      case WHITEBOARD_TOOLS.vanishingPen:
        return this._getVanishingLineObject({ uuid, previewMode, ...options });
      case WHITEBOARD_TOOLS.text:
        return this._getTextObject({ uuid, ...options });
      case WHITEBOARD_TOOLS.image:
        return await this._getImageObject(
          { uuid: fileData.fileId, ...options },
          fileData.fileBase64
        );
      case WHITEBOARD_TOOLS.stickers: {
        let encodedSVG = encodeURIComponent(getStickerSVGContent(options.sticker));

        return await this._getImageObject(
          { uuid, opacity: getOpacityOption(previewMode), x, y, previewMode },
          `data:image/svg+xml,${encodedSVG}`
        );
      }
      case WHITEBOARD_TOOLS.shapes:
        return this._getShapeObject({ uuid, ...options });
    }
  }

  async getPDFDocumentData(file) {
    let [buffer, pdfJsLib] = await Promise.all([file.arrayBuffer(), loadPdfJsLib()]);

    pdfJsLib.disableStream = isEmberTesting();
    return pdfJsLib.getDocument({ data: buffer }).promise;
  }

  async getPDFPageAsDataURL(pdfDocument, pageNumber) {
    let page = await pdfDocument.getPage(pageNumber);
    let viewport = page.getViewport({ scale: 1 });
    let canvas = document.createElement('canvas');
    let renderContext = { canvasContext: canvas.getContext('2d'), viewport };

    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render(renderContext).promise;

    return canvas.toDataURL();
  }

  async _getImageObject(objectOptions, src) {
    let imageElement = await loadImage(src);

    return new WhiteboardImage({
      KonvaConstructor: this.Konva.Image,
      imageElement,
      ...objectOptions,
    });
  }

  _getTextObject(options) {
    return new WhiteboardText({ KonvaConstructor: this.Konva.Text, ...options });
  }

  _getLineObject(options) {
    return new WhiteboardLine({ KonvaConstructor: this.Konva.Line, ...options });
  }

  _getVanishingLineObject(options) {
    return new WhiteboardVanishingLine({
      KonvaConstructor: this.Konva.Line,
      KonvaTween: this.Konva.Tween,
      ...options,
    });
  }

  _getShapeObject({ shape, previewMode, ...rest }) {
    let shapeOptions = WHITEBOARD_SHAPES[shape];

    return new WhiteboardShape({
      ...shapeOptions,
      ...rest,
      shape,
      KonvaConstructor: this._getShapeConstructor(shape),
      opacity: getOpacityOption(previewMode),
      previewMode,
    });
  }

  _getShapeConstructor(shape) {
    switch (shape) {
      case SHAPES.square:
        return Square;
      case SHAPES.triangle:
        return Triangle;
      case SHAPES.circle:
        return Circle;
      case SHAPES.arrow:
        return Arrow;
      case SHAPES.line:
        return Line;
      case SHAPES.grid:
        return Grid;
    }
  }

  async createObject(payload, { local, selectElement } = {}) {
    let { actionData, isLocked, ...options } = payload;
    let boardObject = await this.getBoardObject({ ...options, draggable: false });

    this.objects.set(boardObject.uuid, boardObject);

    if (isLocked !== undefined) {
      boardObject.setIsLocked(isLocked);
    }
    if (local) {
      this._addLocalAction(actionData, boardObject);
    }
    if (selectElement) {
      this.setActiveObject(boardObject);
    }

    return boardObject;
  }

  updateObject(objectId, payload, isLocal) {
    let { actionData, isLocked, ...attrs } = payload;
    let object = this.objects.get(objectId);

    if (isLocked !== undefined) {
      object?.setIsLocked(isLocked);
    }

    if (!attrs || !object) return;

    if (actionData.undo || actionData.redo) {
      object.ensureActionInHistory(actionData, attrs);
      object.processActionStatusChange(actionData.id, actionData.undo);
    } else {
      object.update(attrs, actionData);
    }

    if (object.type === WHITEBOARD_ENTITIES.vanishingLine && !object.isLocked) {
      object.scheduleVanishing();
    } else if (isLocal && !object.isLocked) {
      this._addLocalAction(actionData, object);
    }

    if (
      this.activeObject === object &&
      (object.isDeleted || (!isLocal && isLocked)) &&
      !payload.keepSelection
    ) {
      this.setActiveObject(null);
    }
  }

  createVideoStream(stage) {
    let canvas = document.createElement('canvas');
    canvas.width = HD_VIDEO_CONSTRAINTS.width.exact;
    canvas.height = HD_VIDEO_CONSTRAINTS.height.exact;
    let ctx = canvas.getContext('2d', { alpha: false });
    let videoStream = canvas.captureStream(SCREEN_CONSTRAINTS.video.frameRate);
    let previousTimeStamp;
    let _updateFrame = timestamp => {
      previousTimeStamp ||= timestamp;
      let elapsed = timestamp - previousTimeStamp;
      if (elapsed >= 100) {
        let stageCanvas = stage?.toCanvas();
        if (!stageCanvas || !(stageCanvas.width && stageCanvas.height)) return;

        ctx.drawImage(
          stageCanvas,
          0,
          0,
          stageCanvas.width,
          stageCanvas.height,
          0,
          0,
          HD_VIDEO_CONSTRAINTS.width.exact,
          HD_VIDEO_CONSTRAINTS.height.exact
        );
        previousTimeStamp = timestamp;
      }
      this.videoStreamRequestFrameId = requestAnimationFrame(_updateFrame);
    };
    this.videoStreamRequestFrameId = requestAnimationFrame(_updateFrame);

    return videoStream;
  }

  stopVideoStream() {
    cancelAnimationFrame(this.videoStreamRequestFrameId);
  }

  @action
  _addLocalAction(actionData, object) {
    if (object.isLocked) return;

    this.localActions = [
      ...this.localActions.filter(action => action.timestamp <= this.currentAction?.timestamp),
      new Action({ ...actionData, objectId: object.uuid }),
    ];
  }

  @action
  undoAction(actionId, objectId) {
    let object = this.objects.get(objectId);
    let action = this.localActions.find(action => action.id === actionId);

    if (action) {
      action.undone = true;
    }

    object.processActionStatusChange(actionId, true);

    if (this.activeObject === object && !object.konvaObject.visible()) {
      this.setActiveObject(null);
    }
  }

  @action
  redoAction(actionId, objectId) {
    let object = this.objects.get(objectId);
    let action = this.localActions.find(p => p.id === actionId);

    if (action) {
      action.undone = false;
    }

    object.processActionStatusChange(actionId, false);
  }

  get currentAction() {
    let firstUndone = this.localActions.find(a => a.undone === true);

    return this.localActions[
      firstUndone ? this.localActions.indexOf(firstUndone) - 1 : this.localActions.length - 1
    ];
  }

  get nextAction() {
    return this.localActions.find(a => a.undone === true);
  }

  @action
  setActiveObject(object) {
    this.activeObject = object;
  }
}
