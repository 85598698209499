import { dependentKeyCompat } from '@ember/object/compat';
import { notEmpty, reads } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';
import moment from 'moment-timezone';

export default class CallService extends Service {
  @service session;

  @tracked connectedAt = null;
  @tracked identifier;
  @tracked connectionErrorCode;

  @reads('session.maxParticipantsOnCall') maxParticipantsOnCall;
  @dependentKeyCompat @notEmpty('connectedAt') isConnected;

  @dependentKeyCompat
  get shouldShowRatingPage() {
    let oneMinuteAgo = moment().subtract(1, 'minutes');

    return this.maxParticipantsOnCall > 1 && this.connectedAt?.isBefore(oneMinuteAgo);
  }

  connect(identifier) {
    this.connectedAt = moment();
    this.identifier = identifier;
  }

  disconnect() {
    this.connectedAt = null;
    this.identifier = null;
  }

  setConnectionError(errorCode) {
    this.connectionErrorCode = errorCode;
  }
}
