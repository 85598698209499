import {
  CLIENT_ORIGIN,
  MAX_PARTICIPANTS,
  ROOM_CHANNEL_MESSAGE_TYPES,
  SESSION_EVENTS_TYPES,
} from 'frontend/constants';
import {
  ENSURE_PARTICIPANT_JOIN_TIMEOUT,
  HOST_SETTINGS,
  JOIN_REQUEST_STATUSES,
} from 'frontend/constants/session';
import { action, computed, set, setProperties } from '@ember/object';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { not, notEmpty, reads } from 'macro-decorators';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitForLater } from 'ember-simplepractice/utils/waiters';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

@classic
export default class SessionService extends Service {
  @service('twilio/remote-tracks') remoteTracks;
  @service persistentProperties;
  @service floatingUiElements;
  @service roomChannel;
  @service appointmentSettings;
  @service mixpanel;
  @service soundNotification;
  @service waitingRoom;
  @service remoteLogger;

  firstParticipantJoinTime;
  maxParticipantsOnCall = 0;
  maxParticipantsLimit = MAX_PARTICIPANTS;
  ensureJoinTimeout = isEmberTesting() ? 1 : ENSURE_PARTICIPANT_JOIN_TIMEOUT;
  #joinTimeoutTasks = new Map();

  @tracked participants = [];
  @tracked inWaitingRoom = false;
  @tracked roomModel;
  @tracked joinRequestDeclined = false;
  @tracked user;
  @tracked isRecording = false;
  @tracked roomSid;

  @reads('persistentProperties.userName') userName;
  @reads('persistentProperties.uuid') uuid;
  @reads('persistentProperties.noiseCancellationEnabled') noiseCancellationEnabled;
  @reads('roomModel.origin') origin;
  @reads('roomModel.roomId') roomId;
  @reads('roomModel.clinicianId') clinicianId;
  @reads('roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @reads('roomModel.featureThChime') featureThChime;
  @reads('roomModel.hostJoinTime') hostJoinTime;
  @reads('appointmentSettings.waitingRoomEnabled') waitingRoomEnabled;
  @reads('waitingRoom.admittedRequests') admittedRequests;
  @reads('waitingRoom.pendingRequests') pendingRequests;
  @not('isHost') isGuest;
  @notEmpty('userData.id') isAuthenticated;

  setParticipantUserDataTask = task(async ({ userData, participantSid }) => {
    if (!userData) return;

    while (!this.participants.find(({ identity }) => identity === participantSid)) {
      await timeout(50);
    }

    let sender = this.participants.find(({ identity }) => identity === participantSid);
    let isHost = userData.id === this.roomModel.clinicianId;

    setProperties(sender, { userData, isHost });
  });

  setHostJoinTime(hostJoinTime) {
    if (!this.isHost) return;

    set(this.roomModel, 'hostJoinTime', hostJoinTime);
  }

  get currentParticipantsLimit() {
    return (
      this.maxParticipantsLimit -
      this.admittedRequests.length -
      this.participants.filter(p => p.isHost).length -
      1
    );
  }

  @computed('user', 'userName', 'featureThChime')
  get userData() {
    if (this.user) return this.user;

    return this.featureThChime ? { name: this.userName } : null;
  }

  @computed('userData', 'clinicianId')
  get isHost() {
    return !!(this.userData?.id && this.userData.id === this.clinicianId);
  }

  @computed('origin')
  get isClientOrigin() {
    return this.origin === CLIENT_ORIGIN;
  }

  @computed('roomId')
  get signInUrl() {
    return `/users/sign_in?roomId=${this.roomId}`;
  }

  @computed('roomId')
  get switchAccountUrl() {
    return `/users/switch_accounts?roomId=${this.roomId}`;
  }

  setup({ initialData }) {
    let { settings, userData, ...roomModel } = initialData;

    set(this, 'roomModel', roomModel);

    this.setUserData(userData);
    this.#setupJoinRequestObservers();

    if (!settings) return;

    this.appointmentSettings.change(
      this.isHost
        ? settings
        : Object.fromEntries(
            Object.entries(settings).filter(([key]) => !HOST_SETTINGS.includes(key))
          )
    );
  }

  setUserName(userName) {
    this.persistentProperties.setProps({ userName });

    if (!this.user) return;

    setProperties(this.user, { name: userName });
  }

  setUserData(userData = {}) {
    if (!this.persistentProperties.userName && userData.name) {
      this.persistentProperties.setProps({ userName: userData.name });
    }

    this.user = { ...userData, name: this.userName, clinicianName: userData.name };
  }

  setInWaitingRoom(inWaitingRoom) {
    if (this.isHost || !this.featureThClinicianAuth) return;

    set(this, 'inWaitingRoom', inWaitingRoom);
  }

  addParticipant(participant) {
    if (!this.participants.length && !participant.isHost)
      this.setFirstParticipantJoinTime(moment());
    this.participants = [...this.participants, participant];
    this.maxParticipantsOnCall = Math.max(this.maxParticipantsOnCall, this.participants.length + 1);

    this.#cancelJoinTimeoutTask(participant.identity);

    if (!this.isHost || this.featureThChime) return;
    this.remoteTracks.ensureParticipantHasTracks(participant);
  }

  removeParticipant(identity) {
    this.participants = this.participants.filter(p => p.identity !== identity);
  }

  admitAllPendingRequests() {
    this.waitingRoom.admitAllPendingRequests({
      withDelay: this.featureThChime,
      availableLimit: this.currentParticipantsLimit,
    });
  }

  changeRequestStatus(request, status, options = {}) {
    this.waitingRoom.changeRequestStatus(request, status, options);
  }

  handleNewRequests(requests) {
    let newRequests = requests?.map(r => ({
      ...r,
      isAlreadyOnCall: this.participants.some(p => p.identity === r.uuid),
    }));

    this.waitingRoom.handleNewRequests(newRequests);
  }

  async handleJoinRequestDeclined() {
    this.joinRequestDeclined = true;
    if (isEmberTesting()) return;

    await waitForLater(1000);
    this.roomChannel.unsubscribe();
  }

  sendToWaitingRoom(participant) {
    this.waitingRoom.sendToWaitingRoom({ uuid: participant.identity, name: participant.name });
  }

  showLoginNotification() {
    this.floatingUiElements.showEventNotification({
      text: `Signed in as ${this.userData.name}, ${this.userData.practiceName} Practice`,
      icon: 'check-circle',
    });
  }

  showSentToWaitingRoomNotification() {
    this.floatingUiElements.showEventNotification({ text: 'You’ve been sent to the waiting room' });
  }

  cancelJoinRequest({ uuid, connectionUuid }) {
    this.waitingRoom.cancelJoinRequest({ uuid, connectionUuid });
    this.#cancelJoinTimeoutTask(uuid);
  }

  resetRoomProperties() {
    this.waitingRoom.reset();
    this.joinRequestDeclined = false;
    this.isRecording = false;
    setProperties(this, {
      inWaitingRoom: false,
      participants: [],
      firstParticipantJoinTime: null,
    });
  }

  @action
  shareUserData(receiverUuid) {
    if (!this.userData) return;

    let userData = this.featureThChime ? { ...this.userData, name: this.userName } : this.userData;

    this.#sendSessionMessage(
      { eventType: SESSION_EVENTS_TYPES.shareUserData, userData },
      receiverUuid
    );

    if (this.isHost && this.appointmentSettings.isDirty) {
      this.announceHostControlChange(this.appointmentSettings.participantPermissions);
    }
  }

  processEnding({ hostLeft } = { hostLeft: false }) {
    if (this.isHost) {
      this.roomChannel.addMessageHandler(ROOM_CHANNEL_MESSAGE_TYPES.session, properties => {
        let { message, participantSid: senderUuid } = properties;

        if (message?.eventType === SESSION_EVENTS_TYPES.ended && senderUuid === this.uuid) {
          this.#unsubscribeFromRoomChannel({ withDelay: true });
          this.roomChannel.removeMessageHandler(ROOM_CHANNEL_MESSAGE_TYPES.session);
        }
      });
      if (this.roomChannel.isConnected) {
        this.#sendSessionMessage({ eventType: SESSION_EVENTS_TYPES.ended });
      }
    } else {
      this.#unsubscribeFromRoomChannel({ withDelay: hostLeft });
    }
    this.floatingUiElements.hideRecordingNotification();
    this.mixpanel.track('call left', {
      'is_host': this.isHost,
      reason: hostLeft || this.isHost ? 'clinician_ended' : 'client_left',
    });
  }

  processStart() {
    this.soundNotification.handleStart();
    this.mixpanel.track('call joined', {
      'scheduled_time': this.roomModel.startTime?.toString(),
      'is_host': this.isHost,
      'noise_cancellation': this.noiseCancellationEnabled ? 'enabled' : 'disabled',
      ...(this.isHost && { 'waiting_room': this.waitingRoomEnabled ? 'enabled' : 'disabled' }),
    });
  }

  announceHostControlChange(changedControls) {
    if (!this.roomChannel.isConnected) return;

    this.waitingRoom.joinRequests.forEach(request => {
      this.#sendSessionMessage(
        { eventType: SESSION_EVENTS_TYPES.hostControlsChanged, changedControls },
        request.uuid
      );
    });
  }

  notifyTwilioTrackSubscribed(track, participant) {
    if (!this.isHost) return;

    this.#sendSessionMessage(
      {
        eventType: SESSION_EVENTS_TYPES.trackSubscriptionConfirmed,
        trackName: track.name,
      },
      participant.uuid
    );
  }

  setFirstParticipantJoinTime(firstParticipantJoinTime) {
    set(this, 'firstParticipantJoinTime', firstParticipantJoinTime);
  }

  unsubscribeAfterTimeoutTask = task(async () => {
    await timeout(1_000);
    this.roomChannel.unsubscribe();
  });

  ensureParticipantJoinedTask = task(async request => {
    await timeout(this.ensureJoinTimeout);
    if (this.participants.find(p => p.identity === request.uuid)) return;

    this.floatingUiElements.showEventNotification({
      text: `Failed to admit ${request.name}`,
      duration: isEmberTesting() ? 1 : 10_000,
    });
    this.remoteLogger.warn('Failed to admit participant');
  });

  #sendSessionMessage(message, receiverUuid) {
    if (!this.roomChannel.isConnected) return;
    this.roomChannel.sendMessage(message, ROOM_CHANNEL_MESSAGE_TYPES.session, { receiverUuid });
  }

  #unsubscribeFromRoomChannel({ withDelay = false } = {}) {
    if (!withDelay || isEmberTesting()) return this.roomChannel.unsubscribe();

    return this.unsubscribeAfterTimeoutTask.perform();
  }

  #setupJoinRequestObservers() {
    if (!this.isHost) return;

    this.waitingRoomObserver = {
      onRequestStatusChange: this.#handleJoinRequestStatusChange,
    };
    this.waitingRoom.attachObserver(this.waitingRoomObserver);
  }

  #handleJoinRequestStatusChange = joinRequest => {
    if (joinRequest.status !== JOIN_REQUEST_STATUSES.admitted) return;

    this.#joinTimeoutTasks.set(
      joinRequest.uuid,
      this.ensureParticipantJoinedTask.perform(joinRequest)
    );
  };

  #cancelJoinTimeoutTask(id) {
    this.#joinTimeoutTasks.get(id)?.cancel();
    this.#joinTimeoutTasks.delete(id);
  }

  startRecording() {
    this.isRecording = true;
  }

  stopRecording() {
    this.isRecording = false;
  }
}
