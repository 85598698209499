import {
  ADMIT_DELAY_FACTOR_MS,
  HOST_ADMIN_ACTIONS,
  JOIN_REQUEST_STATUSES,
  SESSION_EVENT_TYPE_BY_STATUS,
} from 'frontend/constants/session';
import { DECLINE_REASONS, ROOM_CHANNEL_MESSAGE_TYPES } from 'frontend/constants';
import { TrackedMap } from 'tracked-built-ins';
import { filter, reads } from 'macro-decorators';
import { waitForLater } from 'ember-simplepractice/utils/waiters';
import JoinRequest from 'frontend/entities/join-request';
import Service, { service } from '@ember/service';

export default class WaitingRoomService extends Service {
  @service appointmentSettings;
  @service floatingUiElements;
  @service mixpanel;
  @service remoteLogger;
  @service roomChannel;
  @service soundNotification;

  #eventObservers = new Set();
  #requestSoundPlayed = false;
  #joinRequestsMap = new TrackedMap();

  @reads('appointmentSettings.waitingRoomEnabled') waitingRoomEnabled;
  @filter('joinRequests', r => r.status === JOIN_REQUEST_STATUSES.admitted) admittedRequests;
  @filter('joinRequests', r => r.status === JOIN_REQUEST_STATUSES.waiting) pendingRequests;

  get pendingRequestsNumber() {
    return this.pendingRequests.length;
  }

  get joinRequests() {
    return Array.from(this.#joinRequestsMap.values()).sort((a, b) => a.timestamp - b.timestamp);
  }

  reset() {
    this.#joinRequestsMap.clear();
    this.#requestSoundPlayed = false;
  }

  admitAllPendingRequests(options = {}) {
    let { withDelay = false, availableLimit } = options;
    let requestsToAdmit = this.pendingRequests.slice(0, availableLimit);

    requestsToAdmit.forEach((request, i) => {
      return this.changeRequestStatus(request, JOIN_REQUEST_STATUSES.admitted, {
        // Fix for the Twilio iOS SDK bug
        sendingDelay: withDelay ? i * ADMIT_DELAY_FACTOR_MS : 0,
      });
    });

    if (this.waitingRoomEnabled) return;

    let requestsToDecline = this.pendingRequests.slice(availableLimit);

    requestsToDecline.forEach(request =>
      this.changeRequestStatus(request, JOIN_REQUEST_STATUSES.declined, {
        payload: { reason: DECLINE_REASONS.participantsLimitReached },
      })
    );
  }

  changeRequestStatus(request, status, options = {}) {
    if (status === request.status) return;

    let { sendingDelay = 0, payload } = options;
    let eventType = SESSION_EVENT_TYPE_BY_STATUS[status];
    let sentToWaitingRoom = status === JOIN_REQUEST_STATUSES.waiting;

    this.#sendRequestStatusMessage({
      eventType,
      payload,
      receiverUuid: request.uuid,
      sendingDelay,
    });

    request.update({ status, sentToWaitingRoom });
    this.#eventObservers.forEach(observer => observer.onRequestStatusChange?.(request));
    if (sentToWaitingRoom) return;

    if (!this.pendingRequestsNumber) {
      this.floatingUiElements.hideJoinRequestNotification();
    }
  }

  sendToWaitingRoom({ uuid, name }) {
    let request = this.#joinRequestsMap.get(uuid);

    if (!request) {
      request = new JoinRequest({ uuid, name, status: JOIN_REQUEST_STATUSES.admitted });
      this.#joinRequestsMap.set(uuid, request);
    }

    this.#trackAdminAction(HOST_ADMIN_ACTIONS.sendToWaitingRoom);
    this.changeRequestStatus(request, JOIN_REQUEST_STATUSES.waiting);
  }

  handleNewRequests(requests) {
    let { waitingRoomEnabled } = this.appointmentSettings;
    let hadPendingRequests = !!this.pendingRequestsNumber;

    this.#updateJoinRequestsList(requests);

    if (!this.pendingRequestsNumber) return;
    if (!waitingRoomEnabled) return this.admitAllPendingRequests();
    if (!this.#requestSoundPlayed) {
      this.soundNotification.playJoinRequestSound();
      this.#requestSoundPlayed = true;
    }
    if (!hadPendingRequests && this.floatingUiElements.joinRequestNotificationHidden) {
      this.floatingUiElements.showShortJoinRequestNotification();
    }
  }

  cancelJoinRequest({ uuid, connectionUuid }) {
    let request = this.joinRequests.find(j => j.connectionUuid === connectionUuid);

    if (!request || request.status === JOIN_REQUEST_STATUSES.declined) return;
    this.remoteLogger.info('%j', { message: 'Cancelled join request', participant: uuid });
    this.#joinRequestsMap.delete(request.uuid);
    if (this.pendingRequestsNumber) return;
    this.floatingUiElements.hideJoinRequestNotification();
  }

  attachObserver(observer) {
    this.#eventObservers.add(observer);
  }

  detachObserver(observer) {
    this.#eventObservers.delete(observer);
  }

  #updateJoinRequestsList(newRequests) {
    newRequests.forEach(request => {
      let existingRequest = this.#joinRequestsMap.get(request.uuid);
      let { connectionUuid } = request;

      if (request.isAlreadyOnCall) return;
      if (existingRequest && connectionUuid && existingRequest.connectionUuid === connectionUuid)
        return;

      this.#joinRequestsMap.set(request.uuid, new JoinRequest(request));
      this.remoteLogger.info('%j', { message: 'Received join request', participant: request.uuid });
    });
  }

  async #sendRequestStatusMessage({ eventType, receiverUuid, sendingDelay, payload = {} }) {
    await waitForLater(sendingDelay);
    if (!this.roomChannel.isConnected) return;

    this.roomChannel.sendMessage({ eventType, ...payload }, ROOM_CHANNEL_MESSAGE_TYPES.session, {
      receiverUuid,
    });
  }

  #trackAdminAction(action) {
    this.mixpanel.track('host admin action', { action });
  }
}
