/* import __COLOCATED_TEMPLATE__ from './join-request.hbs'; */
import {
  JOIN_NOTIFICATION_ACTIONS,
  JOIN_REQUEST_ACTIONS,
  JOIN_REQUEST_ACTION_SOURCES,
} from 'frontend/constants/mixpanel';
import { JOIN_NOTIFICATION_STATES } from 'frontend/services/floating-ui-elements';
import { JOIN_REQUEST_STATUSES } from 'frontend/constants/session';
import { action } from '@ember/object';
import { equal, reads } from 'macro-decorators';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './join-request.module.scss';

export default class NotificationsJoinRequest extends Component {
  @service session;
  @service mixpanel;
  @service floatingUiElements;
  @service waitingRoom;

  styles = styles;

  @reads('waitingRoom.pendingRequests') pendingRequests;
  @reads('waitingRoom.pendingRequestsNumber') pendingRequestsNumber;
  @reads('waitingRoom.admittedRequests') admittedRequests;
  @reads('waitingRoom.joinRequests') joinRequests;
  @equal('pendingRequestsNumber', 1) isSinglePendingRequest;

  get showDetailedView() {
    return (
      this.floatingUiElements.joinRequestNotificationState === JOIN_NOTIFICATION_STATES.detailed
    );
  }

  get isAdmitDisabled() {
    return this.session.currentParticipantsLimit <= 0;
  }

  get isCtaDisabled() {
    return this.isSinglePendingRequest ? this.isAdmitDisabled : false;
  }

  get admitDisabledTooltip() {
    return 'This appointment is at capacity';
  }

  get firstPendingRequest() {
    return this.pendingRequests[0];
  }

  get ctaLabel() {
    return this.isSinglePendingRequest ? 'Admit' : 'View all';
  }

  get title() {
    if (this.isSinglePendingRequest) {
      return `${this.firstPendingRequest.name} wants to join`;
    }

    return 'Multiple people want to join';
  }

  get details() {
    if (this.pendingRequestsNumber < 2) return null;

    let [{ name: firstRequester }, { name: secondRequester }] = this.pendingRequests;

    if (this.pendingRequestsNumber === 2) {
      return `${firstRequester} and ${secondRequester}`;
    }

    let requestsLeft = this.pendingRequestsNumber - 2;
    let othersLabel = requestsLeft === 1 ? 'other' : 'others';

    return `${firstRequester}, ${secondRequester}, and ${requestsLeft} ${othersLabel}`;
  }

  get dropdownOptions() {
    return [
      !this.isSinglePendingRequest && {
        title: 'Admit all',
        onClick: this.onAdmitAllClick,
      },
      { title: 'Hide notification', onClick: this.hideNotification },
      this.isSinglePendingRequest && {
        title: 'Decline',
        negative: true,
        onClick: () => this.onDecline(this.firstPendingRequest),
      },
    ].filter(Boolean);
  }

  get notAllWaiting() {
    return this.joinRequests.some(({ status }) => status !== JOIN_REQUEST_STATUSES.waiting);
  }

  get admitAllButtonLabel() {
    return `Admit ${this.notAllWaiting ? 'remaining' : 'all'}`;
  }

  @action
  onAdmitAllClick() {
    this.#trackRequestInteraction(
      this.notAllWaiting ? JOIN_REQUEST_ACTIONS.admitRemaining : JOIN_REQUEST_ACTIONS.admitAll,
      this.pendingRequests.some(request => request.sentToWaitingRoom)
    );
    this.session.admitAllPendingRequests();
  }

  @action
  onCtaClick() {
    if (this.isSinglePendingRequest) {
      this.onAdmit(this.firstPendingRequest);
    } else {
      this.floatingUiElements.showDetailedJoinRequestNotification();
      this.#trackNotificationInteraction(JOIN_NOTIFICATION_ACTIONS.viewAll);
    }
  }

  @action
  hideNotification() {
    this.#trackNotificationInteraction(JOIN_NOTIFICATION_ACTIONS.hide);
    this.floatingUiElements.hideJoinRequestNotification();
  }

  @action
  onAdmit(request) {
    this.#trackRequestInteraction(JOIN_REQUEST_ACTIONS.admitOne, request.sentToWaitingRoom);
    this.session.changeRequestStatus(request, JOIN_REQUEST_STATUSES.admitted);
  }

  @action
  onDecline(request) {
    this.#trackRequestInteraction(JOIN_REQUEST_ACTIONS.decline);
    this.session.changeRequestStatus(request, JOIN_REQUEST_STATUSES.declined);
  }

  #trackRequestInteraction(action, readmit) {
    this.mixpanel.track('host admit request interaction', {
      action,
      readmit,
      'action_source': this.showDetailedView
        ? JOIN_REQUEST_ACTION_SOURCES.modal
        : JOIN_REQUEST_ACTION_SOURCES.notification,
    });
  }

  #trackNotificationInteraction(action) {
    this.mixpanel.track('host admit notification interaction', { action });
  }
}
