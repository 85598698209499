import { template } from "@ember/template-compiler";
import { CUSTOM_ERROR_CODES } from 'frontend/constants/errors';
import { eq } from 'ember-truth-helpers';
import ErrorsParticipantsLimit from 'frontend/components/errors/participants-limit';
export default template(`
  {{#if (eq @errorCode CUSTOM_ERROR_CODES.participantsLimitReached)}}
    <ErrorsParticipantsLimit @close={{@onClose}} />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
