import { SESSION_EVENTS_TYPES } from 'frontend/constants/index';

export const JOIN_REQUEST_STATUSES = {
  waiting: 'Waiting',
  admitted: 'Admitted',
  declined: 'Declined',
};

export const SESSION_EVENT_TYPE_BY_STATUS = {
  [JOIN_REQUEST_STATUSES.waiting]: SESSION_EVENTS_TYPES.sendToWaitingRoom,
  [JOIN_REQUEST_STATUSES.admitted]: SESSION_EVENTS_TYPES.admitJoin,
  [JOIN_REQUEST_STATUSES.declined]: SESSION_EVENTS_TYPES.declineJoin,
};

export const HOST_SETTINGS = [
  'confirmEnding',
  'timerCountsDown',
  'timerStartType',
  'waitingRoomEnabled',
];

export const HOST_ADMIN_ACTIONS = {
  sendToWaitingRoom: 'send_to_waiting_room',
};

export const ADMIT_DELAY_FACTOR_MS = 5_000;
export const ENSURE_PARTICIPANT_JOIN_TIMEOUT = 10_000;
