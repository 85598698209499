import {
  CHAT_MESSAGE_TYPE,
  ROOM_CHANNEL_MESSAGE_TYPES,
  SESSION_EVENTS_TYPES,
} from 'frontend/constants';
import { DATA_MESSAGE_TOPIC } from 'frontend/services/chime/data-messaging';
import { DataMessage, MeetingSessionCredentials } from 'amazon-chime-sdk-js';
import { waitUntil } from '@ember/test-helpers';
import generateUUID from 'ember-simplepractice/utils/generate-uuid';

export const createAttendee = async (context, userData = { name: 'Remote name' }) => {
  let meetingManager = context.owner.lookup('service:chime.meeting-manager');
  let roomChannelService = context.owner.lookup('service:room-channel');
  let attendeeId = generateUUID();
  let externalUserId = generateUUID();

  await waitUntil(() => !!meetingManager.audioVideo);
  meetingManager.audioVideo.realtimeController.realtimeSetAttendeeIdPresence(
    attendeeId,
    true,
    externalUserId,
    null,
    null
  );
  await waitUntil(() => !!roomChannelService.channel);
  roomChannelService.receiveMessage({
    id: generateUUID(),
    message: {
      eventType: SESSION_EVENTS_TYPES.shareUserData,
      userData,
    },
    participantSid: externalUserId,
    type: ROOM_CHANNEL_MESSAGE_TYPES.session,
  });

  return { attendeeId, externalUserId };
};

export const leaveAttendee = async (context, { attendeeId, externalUserId } = {}) => {
  let meetingManager = context.owner.lookup('service:chime.meeting-manager');

  meetingManager.audioVideo.realtimeController.realtimeSetAttendeeIdPresence(
    attendeeId,
    false,
    externalUserId,
    null,
    null
  );
};

const createMessageMock = ({ message, type }) =>
  JSON.stringify({
    uuid: generateUUID(),
    message,
    type,
  });

export const receiveChatMessage = (context, messageText, sender) => {
  let message = createMessageMock({ message: messageText, type: CHAT_MESSAGE_TYPE });

  receiveDataMessage(context, {
    message,
    senderAttendeeId: sender.attendeeId,
    senderExternalUserId: sender.externalUserId,
  });
};

export const receiveDataMessage = (
  context,
  { message, senderAttendeeId, senderExternalUserId }
) => {
  let meetingManager = context.owner.lookup('service:chime.meeting-manager');

  meetingManager.audioVideo.realtimeController.realtimeReceiveDataMessage(
    new DataMessage(
      Date.now(),
      DATA_MESSAGE_TOPIC,
      new TextEncoder().encode(message),
      senderAttendeeId,
      senderExternalUserId
    )
  );
};

export const mockLocalAttendeeData = () => {
  let credentials = new MeetingSessionCredentials();

  credentials.attendeeId = generateUUID();
  credentials.externalUserId = generateUUID();

  return credentials;
};
