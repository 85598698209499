import { PROCESSOR_TYPES } from 'frontend/constants/backgrounds';
import { computed, set, setProperties } from '@ember/object';
import { computedSessionJSON } from 'frontend/utils/computed-session-storage';
import Service from '@ember/service';
import classic from 'ember-classic-decorator';
import computedPersistentKey, {
  computedPersistentJSON,
} from 'frontend/utils/computed-persistent-key';

export const TYPE_TOOLTIP_SHOWN_COUNT_KEY = 'TwilioVideoApp-wbTypeTooltipShownCount';
export const CHAT_BOX_TOOLTIP_HIDDEN = 'TwilioVideoApp-chatBoxTooltipHidden';
export const AUDIO_PUBLISHED_KEY = 'TwilioVideoApp-isAudioPublished';
export const VIDEO_PUBLISHED_KEY = 'TwilioVideoApp-isVideoPublished';
export const COOKIES_CONFIRMED = 'VideoApp-cookiesConfirmed';

@classic
export default class PersistentPropertiesService extends Service {
  @computed(computedPersistentKey('TwilioVideoApp-uuid')) uuid;
  @computed(computedPersistentKey('TwilioVideoApp-selectedAudioOutput'))
  selectedAudioOutputDeviceId;
  @computed(computedPersistentKey('TwilioVideoApp-selectedAudioInput')) selectedAudioInputDeviceId;
  @computed(computedPersistentKey('TwilioVideoApp-selectedVideoInput')) selectedVideoDeviceId;
  @computed(computedPersistentKey('TwilioVideoApp-selectedVideoQuality')) selectedVideoQuality;
  @computed(computedPersistentJSON('TwilioVideoApp-cropVideo')) cropVideo;
  @computed(computedPersistentJSON('TwilioVideoApp-selectedVideoProcessorType'))
  selectedVideoProcessorType;
  @computed(computedPersistentJSON('TwilioVideoApp-noiseCancellationEnabled'))
  noiseCancellationEnabled;
  @computed(computedPersistentKey('video-username')) userName;
  @computed(computedPersistentJSON('call-timer-enabled')) isTimerEnabled;
  @computed(computedPersistentJSON('TwilioVideoApp-chat-notification-enabled'))
  isChatNotificationEnabled;
  @computed(computedPersistentJSON('TwilioVideoApp-tablet-open-type')) tabletOpenType;
  @computed(computedPersistentJSON('TwilioVideoApp-tablet-open-counter')) tabletOpenCounter;
  @computed(computedPersistentJSON('TwilioVideoApp-tablet-selected-open-type'))
  tabletSelectedOpenType;
  @computed(computedPersistentJSON('TwilioVideoApp-wbComplianceConfirmed')) wbComplianceConfirmed;
  @computed(computedPersistentJSON('TwilioVideoApp-isSelfViewMirrored')) isSelfViewMirrored;
  @computed(computedPersistentJSON(COOKIES_CONFIRMED)) isCookieComplianceConfirmed;
  @computed(computedPersistentJSON(TYPE_TOOLTIP_SHOWN_COUNT_KEY)) wbTypeTooltipShownCount;
  @computed(computedPersistentJSON(CHAT_BOX_TOOLTIP_HIDDEN)) chatBoxTooltipHidden;
  @computed(computedSessionJSON(AUDIO_PUBLISHED_KEY)) isAudioPublished;
  @computed(computedSessionJSON(VIDEO_PUBLISHED_KEY)) isVideoPublished;

  // feature Announcement. Name it with pattern: feature name + suffix "Announced"
  // example: 'VideoApp-featureThTimerAnnounced'
  @computed(computedPersistentKey('VideoApp-featureThAppleReactionsAnnounced'))
  featureThAppleReactionsAnnounced;

  init() {
    super.init(...arguments);
    // backward compatibility for selectedVideoProcessorType with legacy cropVideo flag
    if (this.cropVideo) {
      setProperties(this, {
        selectedVideoProcessorType: PROCESSOR_TYPES.crop,
        cropVideo: false,
      });
    }

    let defaultValues = {
      isChatNotificationEnabled: true,
      isAudioPublished: true,
      isVideoPublished: true,
      isSelfViewMirrored: true,
      userName: '',
      featureThAppleReactionsAnnounced: 0,
      isCookieComplianceConfirmed: false,
    };

    Object.entries(defaultValues).forEach(([property, value]) => {
      if (this[property] === null) set(this, property, value);
    });
  }

  setProp(property, value) {
    set(this, property, value);
  }

  setProps(properties) {
    setProperties(this, properties);
  }
}
