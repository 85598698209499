import { MEDIA_PIPELINE_ATTENDEE_MASK } from 'frontend/constants/chime';
import { confirmation, information } from 'ember-simplepractice/utils/modals';
import { reads } from 'macro-decorators';
import { requestRecordingStart, requestRecordingStop } from 'frontend/utils/api/recording';
import { setProperties } from '@ember/object';
import { task, waitForProperty } from 'ember-concurrency';
import Service, { service } from '@ember/service';

const RECORDING_FAILED_MESSAGE = 'Failed to start recording';
const STOP_RECORDING_FAILED_MESSAGE = 'Failed to stop recording';

class RecordingError extends Error {
  constructor() {
    super(...arguments);
    this.name = 'RecordingError';
  }
}

export default class ChimeRecordingService extends Service {
  @service('chime.meeting-manager') meetingManager;
  @service mixpanel;
  @service session;
  @service errorHandling;

  mediaPipelineAttendee = null;

  @reads('meetingManager.audioVideo') audioVideo;

  get isRecordingActionAvailable() {
    return !this.startRecordingTask.isRunning && !this.stopRecordingTask.isRunning;
  }

  setup() {
    this.audioVideo.realtimeSubscribeToAttendeeIdPresence(this.#handleAttendeePresence);
  }

  reset() {
    this.audioVideo?.realtimeUnsubscribeToAttendeeIdPresence(this.#handleAttendeePresence);
  }

  startRecording(meetingId) {
    this.startRecordingTask.perform({ meetingId });
  }

  stopRecording(meetingId, options = {}) {
    let { withConfirmation = true } = options;
    this.stopRecordingTask.perform({ meetingId, withConfirmation });
  }

  startRecordingTask = task(async ({ meetingId }) => {
    let response = await requestRecordingStart({ meetingId });

    if (!response.ok)
      return this.#handleError({
        response,
        message: RECORDING_FAILED_MESSAGE,
        ignoredCodes: [409],
      });

    await waitForProperty(this, 'mediaPipelineAttendee', Boolean);
  });

  stopRecordingTask = task(async ({ meetingId, withConfirmation }) => {
    if (withConfirmation) {
      let { dismiss } = await confirmation({
        title: 'Stop recording?',
        text: 'The recording will be stopped and the note generation process will be started.',
        confirmButtonText: 'Stop',
        cancelButtonText: 'Cancel',
      });

      if (dismiss) return;
    }

    let response = await requestRecordingStop({ meetingId });

    if (!response.ok)
      return this.#handleError({ response, message: STOP_RECORDING_FAILED_MESSAGE });

    await waitForProperty(this, 'mediaPipelineAttendee', null);
  });

  #handleAttendeePresence = (_, isPresent, externalUserId) => {
    if (!MEDIA_PIPELINE_ATTENDEE_MASK.test(externalUserId)) return;

    setProperties(this, { mediaPipelineAttendee: isPresent ? externalUserId : null });
    return isPresent ? this.session.startRecording() : this.session.stopRecording();
  };

  async #handleError({ response, message, ignoredCodes = [] } = {}) {
    let cause = null;
    try {
      let { errors = [] } = await response.json();

      if (ignoredCodes.includes(errors?.[0]?.code)) return;

      cause = errors?.[0];
    } catch (err) {
      cause = err;
    }

    this.errorHandling.notifyError(new RecordingError(RECORDING_FAILED_MESSAGE, { cause }));
    return information({
      title: 'Recording failed',
      text: message,
      confirmButtonText: 'Dismiss',
    });
  }
}
