import { computed, set } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';
import { empty, not, or, reads } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class TwilioVideoStreamService extends Service {
  @service('twilio/room') twilioRoom;
  @service session;

  showSidebar = true;

  @reads('twilioRoom.roomModel') roomModel;
  @reads('twilioRoom.connected') connected;
  @reads('twilioRoom.localParticipant') localParticipant;
  @reads('twilioRoom.sharedEntity') sharedEntity;
  @reads('twilioRoom.shareParticipant') shareParticipant;
  @reads('twilioRoom.selectedParticipant') selectedParticipant;
  @reads('twilioRoom.dominantSpeaker') dominantSpeaker;
  @reads('twilioRoom.isAudioPublished') isAudioPublished;
  @reads('twilioRoom.isVideoPublished') isVideoPublished;
  @reads('twilioRoom.isSharingScreen') isSharingScreen;
  @reads('twilioRoom.mainSpeaker') mainSpeaker;
  @reads('twilioRoom.hideSelfView') hideSelfView;
  @reads('twilioRoom.gridView') gridView;
  @reads('twilioRoom.isHost') isHost;
  @reads('twilioRoom.hasConfirmedTrack') hasConfirmedTrack;
  @reads('roomModel.featureThEnsureTracks') featureThEnsureTracks;
  @reads('roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @empty('participants') noParticipants;
  @not('isSharingScreen') notSharingScreen;
  @or('selectedParticipant', 'shareParticipant') hasFixedMainView;

  @dependentKeyCompat
  get participants() {
    return this.session.participants.filter(p => p.independent === true);
  }

  @computed(
    'participants.length',
    'selectedParticipant',
    'sharedEntity',
    'hasConfirmedTrack',
    'featureThClinicianAuth',
    'featureThEnsureTracks',
    'isHost'
  )
  get hasMainView() {
    let isMainViewShown = this.participants.length || this.selectedParticipant || this.sharedEntity;

    if (!this.featureThEnsureTracks || this.isHost || !this.featureThClinicianAuth)
      return isMainViewShown;

    return isMainViewShown && this.hasConfirmedTrack;
  }

  toggleAudio() {
    return this.twilioRoom.toggleLocalAudio();
  }

  toggleVideo() {
    return this.twilioRoom.toggleLocalVideo();
  }

  disconnect() {
    if (this.twilioRoom.inWaitingRoom) {
      this.twilioRoom.leaveTestRoom({ endCall: true });
    } else {
      this.twilioRoom.leaveRoom();
    }

    set(this, 'showSidebar', true);
  }

  handlePin(participant) {
    this.twilioRoom.handlePin(participant);
  }

  toggleHideSelfView() {
    this.twilioRoom.toggleHideSelfView();
  }

  toggleGridView() {
    this.twilioRoom.toggleGridView();
  }

  toggleScreenSharing() {
    return this.twilioRoom.toggleScreenSharing();
  }
}
