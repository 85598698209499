/* import __COLOCATED_TEMPLATE__ from './host-control-settings.hbs'; */
import {
  EVENT_NAMES_BY_CONTROL,
  HOST_CONTROLS,
  PARTICIPANT_PERMISSIONS,
} from 'frontend/constants/settings';
import { action } from '@ember/object';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class SettingsModalHostControlSettings extends Component {
  @service appointmentSettings;
  @service session;
  @service mixpanel;

  hostControls = HOST_CONTROLS;

  @reads('appointmentSettings.waitingRoomEnabled') waitingRoomEnabled;
  @reads('appointmentSettings.confirmEnding') confirmEnding;
  @reads('appointmentSettings.isSettingUpdating') isSettingUpdating;
  @reads('appointmentSettings.chatEnabled') chatEnabled;
  @reads('appointmentSettings.sharingEnabled') sharingEnabled;
  @reads('appointmentSettings.backgroundEnabled') backgroundEnabled;
  @reads('session.roomModel.featureThHostControls') featureThHostControls;

  @action
  toggleControl(controlName) {
    let enabled = !this[controlName];
    let updatedControl = { [controlName]: enabled };

    this.appointmentSettings.update(updatedControl);
    if (PARTICIPANT_PERMISSIONS.includes(controlName)) {
      this.session.announceHostControlChange(updatedControl);
    }
    if (controlName === HOST_CONTROLS.waitingRoomEnabled && !enabled) {
      this.session.admitAllPendingRequests();
    }
    this.mixpanel.track(`${EVENT_NAMES_BY_CONTROL[controlName]} updated`, {
      'status': enabled ? 'enabled' : 'disabled',
    });
  }
}
