/* import __COLOCATED_TEMPLATE__ from './cookies-banner.hbs'; */
import { modifier } from 'ember-modifier';
import { not } from 'macro-decorators';
import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import Component from '@glimmer/component';
import fade from 'ember-animated/transitions/fade';
import styles from './cookies-banner.module.scss';

export default class WelcomeScreenCookiesBanner extends Component {
  @service persistentProperties;
  @service floatingUiElements;

  fadeTransition = fade;
  componentClassName = styles.component;

  @not('persistentProperties.isCookieComplianceConfirmed') isBannerShown;

  onInsert = modifier(() => {
    this.automaticallyAcceptCookies.perform();
  });

  willDestroy() {
    super.willDestroy(...arguments);
    this.#setCookieComplianceConfirmed(true);
  }

  automaticallyAcceptCookies = task(async () => {
    await timeout(this.floatingUiElements.cookiesBannerShownDuration);
    this.#setCookieComplianceConfirmed(true);
  });

  onCloseClick = () => {
    this.persistentProperties.setProps({ isCookieComplianceConfirmed: true });
  };

  #setCookieComplianceConfirmed(isCookieComplianceConfirmed) {
    this.persistentProperties.setProps({ isCookieComplianceConfirmed });
  }
}
