import { template } from "@ember/template-compiler";
import { NETWORK_QUALITY_TIMEOUT } from 'frontend/constants/twilio';
import { action } from '@ember/object';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { modifier } from 'ember-modifier';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitForNext } from 'ember-simplepractice/utils/waiters';
import Component from '@glimmer/component';
import ENV from 'frontend/config/environment';
import NetworkQualityIndicator from 'frontend/components/network-quality-indicator';
import PermissionsModal from 'frontend/components/permissions-modal';
import WelcomeScreenCookiesBanner from 'frontend/components/welcome-screen/cookies-banner';
import WelcomeScreenLocalVideoControls from 'frontend/components/welcome-screen/local-video-controls';
import WelcomeScreenPreviewVideo from 'frontend/components/welcome-screen/preview-video';
import WelcomeScreenRoomInstructions from 'frontend/components/welcome-screen/room-instructions';
import styles from './welcome-screen.module.scss';
const TWILIO_TESTING_TIMEOUT = 1_000;
export default class WelcomeScreen extends Component {
    @service('twilio/room')
    twilioRoom;
    @service('twilio/video-stream')
    videoStream;
    @service('chime.meeting-manager')
    meetingManager;
    @service('chime.roster')
    rosterService;
    @service
    appointment;
    @service
    session;
    @service
    mediaDevices;
    @service
    mediaSettings;
    @service
    router;
    @service
    audioVideoAdapter;
    @service
    errorHandling;
    @service
    call;
    @tracked
    isRoomDisconnected = false;
    @tracked
    videoWrapperContainer = null;
    @reads('twilioRoom.localParticipant')
    localParticipant;
    @reads('twilioRoom.localParticipant.reconnecting')
    reconnecting;
    @reads('twilioRoom.localNetworkQualityLevel')
    twilioNetworkQualityLevel;
    @reads('twilioRoom.roomModel.featureTwilioPeerToPeer')
    featureTwilioPeerToPeer;
    @reads('twilioRoom.room')
    twilioRoomEntity;
    @reads('session.roomModel.featureThChime')
    featureThChime;
    @reads('session.roomModel.featureThClinicianAuth')
    featureThClinicianAuth;
    @reads('audioVideoAdapter.isVideoPublished')
    isVideoPublished;
    @reads('audioVideoAdapter.isAudioPublished')
    isAudioPublished;
    @reads('audioVideoAdapter.isMicrophoneAvailable')
    isMicrophoneAvailable;
    @reads('audioVideoAdapter.isCameraAvailable')
    isCameraAvailable;
    @reads('meetingManager.localAttendeeId')
    localAttendeeId;
    @reads('rosterService.localAttendeeData')
    localAttendeeData;
    @reads('mediaDevices.isRequestingPermissions')
    isRequestingPermissions;
    get hideQualityIndicator() {
        return this.isRoomDisconnected || this.featureTwilioPeerToPeer;
    }
    get networkQualityLevel() {
        if (this.featureThChime) {
            if (this.localAttendeeData?.audioSignalScore === undefined) return undefined;
            if (!this.isVideoPublished) return this.localAttendeeData?.audioSignalScore;
            return this.localAttendeeData?.networkLevelScore;
        }
        return this.twilioNetworkQualityLevel;
    }
    get submitDisabled() {
        if (this.isRequestingPermissions || this.leaveTestCallTask.isRunning) return true;
        return !(this.twilioRoomEntity || this.featureThChime);
    }
    initTestTwilioRoom = task(async ()=>{
        try {
            await this.twilioRoom.joinTestRoom({
                isWelcomeScreen: true
            });
            if (!ENV.skipTasksSleep) {
                await timeout(isEmberTesting() ? TWILIO_TESTING_TIMEOUT : NETWORK_QUALITY_TIMEOUT);
                this.twilioRoom.room.disconnect();
                this.isRoomDisconnected = true;
            }
        } catch (error1) {
            this.processError(error1);
        }
    });
    @action
    toggleLocalAudio() {
        return this.audioVideoAdapter.toggleLocalAudio();
    }
    @action
    toggleLocalVideo() {
        this.audioVideoAdapter.toggleLocalVideo();
    }
    @action
    openSettings() {
        this.mediaSettings.openSettingsModal();
    }
    @action
    async joinCall() {
        if (this.featureThChime && (this.session.isHost || !this.featureThClinicianAuth)) {
            await this.leaveTestCallTask.perform();
        } else {
            this.twilioRoom.leaveTestRoom();
        }
        return this.router.transitionTo('appointment.room', this.session.roomModel.roomId);
    }
    onWelcomeScreenInsert = modifier(async (element1)=>{
        await waitForNext();
        this.videoWrapperContainer = element1.parentElement;
        try {
            if (this.featureThChime) {
                await this.appointment.initSessionTask.perform({
                    isTestSession: true
                });
            } else {
                await this.mediaDevices.takeDevicesControl();
                this.initTestTwilioRoom.perform();
            }
        } catch (error1) {
            this.processError(error1);
        }
    });
    async processError(error1) {
        if (this.isDestroying || this.isDestroyed) return;
        if (this.errorHandling.isGetDevicesError(error1)) {
            this.errorHandling.getDevicesError(error1);
        } else {
            window._bugsnagClient?.notify(error1);
        }
    }
    leaveTestCallTask = task(async ()=>{
        await this.appointment.leave({
            endCall: false
        });
    });
    static{
        template(`
    <div class={{styles.component}} ...attributes>
      <div
        id="welcome-screen-content-wrapper"
        data-test-twilio-welcome-screen
        class="content-wrapper"
        {{this.onWelcomeScreenInsert}}
      >
        {{#if this.isRequestingPermissions}}
          <PermissionsModal />
        {{/if}}

        <div class="resizable-content-wrapper">
          <div class="information-text">Here's how you'll appear in the appointment.</div>

          <div class="local-video-wrapper">
            {{#if this.videoWrapperContainer}}
              <WelcomeScreenPreviewVideo
                @reconnecting={{this.reconnecting}}
                @parentContainer={{this.videoWrapperContainer}}
              />
            {{/if}}
            {{#unless this.hideQualityIndicator}}
              <NetworkQualityIndicator
                @level={{this.networkQualityLevel}}
                @reconnectingRoom={{this.reconnecting}}
              />
            {{/unless}}
            <WelcomeScreenLocalVideoControls
              @classNames="video-controls-container"
              @toggleLocalAudio={{this.toggleLocalAudio}}
              @toggleLocalVideo={{this.toggleLocalVideo}}
              @openSettings={{this.openSettings}}
              @isAudioPublished={{this.isAudioPublished}}
              @isVideoPublished={{this.isVideoPublished}}
              @isMicrophoneAvailable={{this.isMicrophoneAvailable}}
              @isCameraAvailable={{this.isCameraAvailable}}
            />
          </div>
        </div>
      </div>
      <WelcomeScreenRoomInstructions
        @submitDisabled={{this.submitDisabled}}
        @submitForm={{this.joinCall}}
      />
      <WelcomeScreenCookiesBanner />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
