import * as ActionCable from '@rails/actioncable';
import { reads } from 'macro-decorators';
import Service, { service } from '@ember/service';

export default class ActionCableService extends Service {
  @service persistentProperties;

  @reads('persistentProperties.uuid') uuid;

  createConsumer({ clinicianId, roomId }) {
    let socketUrl = `/websocket?uuid=${this.uuid}&room_id=${roomId}`;

    return ActionCable.createConsumer(
      clinicianId ? `${socketUrl}&clinician_id=${clinicianId}` : socketUrl
    );
  }

  createSubscription(consumer, channelName, mixin) {
    let resolve, reject;
    let asyncConnect = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    let channel = consumer.subscriptions.create(channelName, {
      ...mixin,
      rejected: () => {
        reject();
        mixin.rejected?.();
      },
      connected: () => {
        resolve(channel);
        mixin.connected?.();
      },
    });
    channel.asyncConnect = () => asyncConnect;

    return channel;
  }
}
