import { ROOM_CHANNEL_MESSAGE_TYPES, TEN_SECONDS_MS } from 'frontend/constants';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import MessagingService from 'frontend/services/messaging';
import generateUUID from 'ember-simplepractice/utils/generate-uuid';
import moment from 'moment-timezone';

export const WEBSOCKET_CHANNEL_NAME = 'RoomChannel';
export const MESSAGE_TTL_MS = 30_000; // 30 seconds
const RESCHEDULE_SENDING_PERIOD = 3000;
const CLEANUP_PERIOD = 5000;
const ENSURE_CONNECTION_TIMEOUT = TEN_SECONDS_MS;
const ROOM_LIFE_TIME_AFTER_END = 12 * 60 * 60 * 1000; // 12 hours

/* eslint-disable-next-line @simplepractice/simplepractice/require-native-class-id */
export default class RoomChannelService extends MessagingService {
  @service errorHandling;
  @service actionCable;
  @service mixpanel;
  @service remoteLogger;

  @tracked isConnected;

  channel;
  unacknowledgedMessages = new Map();
  processedMessages = new Map();
  ensureConnectionTimeout = ENSURE_CONNECTION_TIMEOUT;
  #failureListeners = [];

  get isConnecting() {
    return !this.subscribeTask.performCount || this.subscribeTask.isRunning;
  }

  subscribe(options) {
    this.subscribeTask.perform(options);
    this.cleanupProcessedMessagesTask.perform();
    this.ensureConnectionEstablished.perform();
  }

  receiveMessage(messageObject) {
    let { id } = messageObject;

    if (messageObject.type === ROOM_CHANNEL_MESSAGE_TYPES.acknowledge) {
      this.unacknowledgedMessages.delete(id);
      return;
    }

    if (id) {
      this.channel.send({ type: ROOM_CHANNEL_MESSAGE_TYPES.acknowledge, id });

      if (this.processedMessages.has(id)) return;

      this.processedMessages.set(id, Date.now());
    }

    this.messageHandlers[messageObject.type]?.(messageObject);
  }

  sendMessage(message = {}, type = '', options = {}) {
    let messageObject = this.createMessage(message, type, { id: generateUUID(), ...options });

    this.unacknowledgedMessages.set(messageObject.id, messageObject);

    if (!this.isConnected) return;

    this.channel.send(messageObject);
  }

  unsubscribe() {
    this.channel?.consumer.disconnect();
    this.channel?.unsubscribe();
    this.remoteLogger.info('Unsubscribed from room channel');
    this.resendUnacknowledgedMessagesTask.cancelAll();
    this.cleanupProcessedMessagesTask.cancelAll();
    this.unacknowledgedMessages.clear();
    this.processedMessages.clear();
    this.ensureConnectionEstablished.cancelAll();
    this.subscribeTask.cancelAll();
    this.#failureListeners = [];
    this.isConnected = false;
    this.channel = null;
  }

  subscribeTask = task(async options => {
    let { roomId, userName, clinicianId, endTime } = options;
    let roomEOLTimestamp = endTime.toDate().getTime() + ROOM_LIFE_TIME_AFTER_END;

    try {
      this.channel = this.actionCable.createSubscription(
        this.actionCable.createConsumer({ clinicianId, roomId }),
        { channel: WEBSOCKET_CHANNEL_NAME, roomId, userName },
        {
          received: message => this.receiveMessage(JSON.parse(message)),
          connected: () => this.onConnected(),
          disconnected: ({ willAttemptReconnect }) => {
            this.remoteLogger.info('Disconnected from room channel');

            if (!willAttemptReconnect) return;
            this.ensureConnectionEstablished.perform();
          },
        }
      );
      this.remoteLogger.info('Created room channel subscription');
      await this.channel.asyncConnect();
      this.#patchConnectionReopen(roomEOLTimestamp);
    } catch (e) {
      this.errorHandling.roomChannelSubscriptionFailedError();
      this.#publishConnectionFail();
    }
  });

  resendUnacknowledgedMessagesTask = task(async () => {
    await this.subscribeTask.last;
    while (!this.isDestroyed) {
      for (let [id, message] of this.unacknowledgedMessages.entries()) {
        this.channel?.send(message);

        if (moment().diff(message.timestamp) >= MESSAGE_TTL_MS) {
          this.unacknowledgedMessages.delete(id);
        }
      }

      if (isEmberTesting()) return;

      await timeout(RESCHEDULE_SENDING_PERIOD);
    }
  });

  cleanupProcessedMessagesTask = task(async () => {
    let currentTime = Date.now();

    while (!this.isDestroyed) {
      for (let [key, timestamp] of this.processedMessages.entries()) {
        if (currentTime - timestamp >= MESSAGE_TTL_MS) {
          this.processedMessages.delete(key);
        }
      }

      if (isEmberTesting()) return;

      await timeout(CLEANUP_PERIOD);
    }
  });

  ensureConnectionEstablished = task(async () => {
    await timeout(this.ensureConnectionTimeout);
    if (this.isConnected) return;

    this.remoteLogger.info('Failed to connect to room channel');
    this.errorHandling.roomChannelSubscriptionFailedError({ isTimeout: true });
    return this.#publishConnectionFail();
  });

  subscribeToConnectionFail(callback) {
    this.#failureListeners.push(callback);
  }

  #publishConnectionFail() {
    this.#failureListeners.forEach(callback => callback());
  }

  #patchConnectionReopen(roomEOLTimestamp) {
    try {
      let originalReopen = this.channel.consumer.connection.reopen;
      let showError = () => {
        this.errorHandling.roomDisconnectedError();
        this.#publishConnectionFail();
      };

      this.channel.consumer.connection.reopen = function () {
        if (Date.now() >= roomEOLTimestamp) return showError();

        return originalReopen.call(this);
      };
    } catch (e) {
      // noop
    }
  }

  onConnected() {
    this.remoteLogger.info('Connected to room channel');
    this.isConnected = true;
    this.ensureConnectionEstablished.cancelAll();
    this.resendUnacknowledgedMessagesTask.perform();
  }
}
