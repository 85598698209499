import { didCancel, race } from 'ember-concurrency';

let cancelSafeRace = async tasks => {
  try {
    return await race(tasks);
  } catch (e) {
    if (!didCancel(e)) throw e;
  }
};

export default cancelSafeRace;
