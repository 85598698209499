/* import __COLOCATED_TEMPLATE__ from './whiteboard.hbs'; */
import {
  BASE_HEIGHT,
  BASE_WIDTH,
  DEFAULT_OPACITY,
  DRAWING_EVENT,
  DRAW_DELAY_MS,
  EVENT_TYPES,
  FILE_TYPES,
  MESSAGE_TYPE,
  PEN_SIZE_OPTIONS,
  PREVIEWABLE_NAMESPACE,
  PREVIEWABLE_TOOLS,
  SHAPES,
  STICKERS,
  STICKERS_CONFIG,
  TEXT_HEIGHT_MULTIPLIER,
  TEXT_WIDTH_MULTIPLIER,
  WHITEBOARD_COLORS,
  WHITEBOARD_TOOLS,
} from 'frontend/constants/whiteboard';
import { DATA_TRACK_MESSAGE_TYPES, GLOBAL_EVENT, GLOBAL_EVENT_TYPES } from 'frontend/constants';
import { ROOM_EVENTS } from 'frontend/constants/twilio';
import { TrackedArray } from 'tracked-built-ins';
import { action, set, setProperties } from '@ember/object';
import { blobToBase64 } from 'frontend/utils/files';
import { classNames } from '@ember-decorators/component';
import { destruction, information } from 'frontend/utils/modals';
import { equal, not, or, reads } from '@ember/object/computed';
import {
  fitInStage,
  getCursorStyle,
  getPointerPosition,
  getWhiteboardJSON,
  isDoubleClick,
  isText,
} from 'frontend/utils/whiteboard';
import { getOwner } from '@ember/owner';
import { keepLatestTask, task, timeout } from 'ember-concurrency';
import { modifier } from 'ember-modifier';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@ember/component';
import TEXT_SIZES from 'frontend/constants/text-sizes';
import classic from 'ember-classic-decorator';
import generateUUID from 'ember-simplepractice/utils/generate-uuid';
import styles from './whiteboard.module.scss';

@classic
@classNames(styles.component)
export default class Whiteboard extends Component {
  @service('twilio/room') twilioRoom;
  @service('chime.roster') rosterService;
  @service('whiteboard') whiteboardService;
  @service errorHandling;
  @service mixpanel;
  @service session;
  @service audioVideoAdapter;

  @reads('whiteboardService.isLocked') isWhiteboardLocked;
  @equal('tool', WHITEBOARD_TOOLS.select) isSelectTool;
  @equal('tool', WHITEBOARD_TOOLS.text) isTextTool;
  @equal('tool', WHITEBOARD_TOOLS.draw) isDrawTool;
  @equal('tool', WHITEBOARD_TOOLS.shapes) isShapesTool;
  @equal('tool', WHITEBOARD_TOOLS.stickers) isStickersTool;
  @equal('tool', WHITEBOARD_TOOLS.vanishingPen) isVanishingPenTool;
  @reads('whiteboardService.objects') objects;
  @reads('whiteboardService.currentAction') currentAction;
  @reads('whiteboardService.currentActionIndex') currentActionIndex;
  @reads('session.roomModel.featureThChime') featureThChime;
  @reads('audioVideoAdapter.isSharing') isSharing;
  @not('isWhiteboardLocked') isUnlocked;
  @or('isUnlocked', 'isSharing') activeObjectAvailable;
  @or('isDrawTool', 'isVanishingPenTool') isPenTool;

  @tracked selectedPreviewIndex = 0;
  pdfPagesPreviews = new TrackedArray([]);

  stage;
  layer;
  tool = WHITEBOARD_TOOLS.select;
  drawColor = WHITEBOARD_COLORS.black;
  textColor = WHITEBOARD_COLORS.black;
  textSize = TEXT_SIZES.XL;
  shape = SHAPES.triangle;
  sticker = STICKERS.star;
  penSizeOption = PEN_SIZE_OPTIONS.medium;
  canvasContainerId = 'canvas-container';
  toolbarId = 'toolbar';
  transformerId = 'transformer';
  objectPreview;

  init() {
    super.init(...arguments);
    let messagingServiceName = this.featureThChime
      ? 'service:chime.data-messaging'
      : 'service:twilio.data-track';
    this.messagingService = getOwner(this).lookup(messagingServiceName);
  }

  get activeObject() {
    return this.whiteboardService.activeObject;
  }

  onSetupAndTeardown = modifier(element => {
    this.initWhiteboard(element);

    return () => {
      this.finishWhiteboard();
    };
  });

  uploadPdfTask = task(async submitEvent => {
    submitEvent?.preventDefault();

    while (!this.pdfPagesPreviews?.[this.selectedPreviewIndex]) {
      await timeout(100);
    }

    this.processUploadedImage(this.pdfPagesPreviews[this.selectedPreviewIndex], FILE_TYPES.png);
    this.closePdfPreviewModal();
  });

  @action
  onSelectTool(toolSlug) {
    setProperties(this, { tool: toolSlug, toolTracked: false });
    this.selectObject(null);
  }

  async initWhiteboard(container) {
    let stage = await this.whiteboardService.initStage(container);
    let layer = this.whiteboardService.getLayer();

    setProperties(this, { stage, layer });
    this.stage.add(layer);
    this.addStageEventListeners(this.stage);
    this.whiteboardService.addBackgroundToLayer(layer);
    this.fitStageIntoParentContainer(container);
    this.messagingService.addMessageHandler(MESSAGE_TYPE, this.receiveMessage);

    this.#subscribeToNewAttendees();

    if (this.isSharing) {
      this.trackMixpanelEvent('whiteboard shared');
      this.#shareVideoStream();
    }
    await this._initWhiteboardFromJSON();
  }

  @action
  fitStageIntoParentContainer(parentContainer) {
    if (!this.stage) {
      return;
    }
    let scale = parentContainer.clientWidth / BASE_WIDTH;

    this.stage.width(BASE_WIDTH * scale);
    this.stage.height(BASE_HEIGHT * scale);
    this.stage.scale({ x: scale, y: scale });
  }

  addStageEventListeners(stage) {
    stage.on('mousedown touchstart', () => {
      if (this.isPenTool) {
        this.startDrawing();
        this.stage.on(DRAWING_EVENT, this.drawing);
        this._trackToolUsage();
      }
    });
    stage.on('mouseup touchend', () => {
      if (this.isPenTool && this.activeObject) {
        this.finishDrawing();
      }
    });
    stage.on('click tap', event => {
      switch (this.tool) {
        case WHITEBOARD_TOOLS.text:
          this._handleTextToolClick(event);
          break;
        case WHITEBOARD_TOOLS.select:
          this._handleSelectToolClick(event);
          break;
        case WHITEBOARD_TOOLS.shapes:
        case WHITEBOARD_TOOLS.stickers:
          this._handlePreviewableToolTap(event);
          break;
      }
      this._trackToolUsage();
    });
    this._addMouseEnterListener(stage);
    stage.on('mouseleave', () => {
      if (this.isPenTool && this.activeObject) {
        this.finishDrawing();
      }
      this.objectPreview?.konvaObject.destroy();
    });
  }

  finishWhiteboard() {
    this.messagingService.removeMessageHandler(MESSAGE_TYPE);
    this.stage?.destroy();
    this.whiteboardService.cleanupWhiteboard();

    if (!this.featureThChime) return;

    this.rosterService.unsubscribeFromRosterChanges(this._onChimeRosterChange);
  }

  @action
  receiveMessage(properties) {
    let { eventType, objectId, ...payload } = properties.message;

    switch (eventType) {
      case EVENT_TYPES.create:
        this._createObject(payload, { local: false, selectElement: false });
        break;
      case EVENT_TYPES.update:
        this._updateObject(objectId, payload, false);
        break;
      case EVENT_TYPES.clear:
        this._resetWhiteboard();
        break;
    }
  }

  @action
  sendMessage(message, type) {
    this.messagingService.sendMessage(
      {
        objectId: this.activeObject?.uuid,
        ...message,
      },
      type || MESSAGE_TYPE
    );
  }

  @action
  startDrawing() {
    let { x, y } = getPointerPosition(this.stage);
    let options = {
      isLocked: true,
      opacity: this.penSizeOption.opacity || DEFAULT_OPACITY,
      points: [x, y],
      stroke: this.drawColor,
      strokeWidth: this.penSizeOption.size,
      tool: this.tool,
      uuid: generateUUID().substring(0, 8),
    };

    this._createObject(options, { sync: true });
  }

  @action
  async createText() {
    let attributes = {
      width: TEXT_WIDTH_MULTIPLIER * this.textSize,
      height: TEXT_HEIGHT_MULTIPLIER * this.textSize,
      ...getPointerPosition(this.stage),
    };
    let arrangedCoordinates = fitInStage(attributes);
    let options = {
      ...arrangedCoordinates,
      fill: this.textColor,
      fontSize: this.textSize,
      isLocked: true,
      tool: this.tool,
      uuid: generateUUID(),
    };

    this._createObject(options, { sync: true });
  }

  async _createObject(options, { sync = false, local = true, selectElement = true } = {}) {
    let actionData = local
      ? { id: generateUUID().substring(0, 8), timestamp: Date.now() }
      : options.actionData;
    let payload = { ...options, actionData };
    let boardObject = await this.whiteboardService.createObject(payload, { local, selectElement });

    boardObject.addToLayer(this.layer, actionData);
    if (sync) {
      this.sendMessage({ eventType: EVENT_TYPES.create, ...payload });
    }
    return { boardObject, actionData };
  }

  @action
  drawing({ evt }) {
    this.drawTask.perform(evt);
  }

  drawTask = keepLatestTask(async evt => {
    let { x, y } = getPointerPosition(this.stage);
    let points = [...this.activeObject.points, x, y];

    evt.preventDefault();
    this._updateObject(this.activeObject.uuid, { points });
    await timeout(DRAW_DELAY_MS);
  });

  _updateObject(objectId, options, sync = true) {
    let { actionData = { id: generateUUID(), timestamp: Date.now() } } = options;
    let payload = { ...options, actionData };

    this.whiteboardService.updateObject(objectId, payload, sync);
    if (sync) {
      this.sendMessage({ eventType: EVENT_TYPES.update, ...payload, objectId });
    }
  }

  @action
  finishDrawing() {
    this.stage.off(DRAWING_EVENT);
    this.drawTask.cancelAll();

    let newPoints = [];
    let { points } = this.activeObject;
    let x = Math.min(...points.filter((e, i) => (i % 2) - 1));
    let y = Math.min(...points.filter((e, i) => i % 2));

    for (let i = 0; i < points.length; i += 2) {
      newPoints.push(points[i] - x, points[i + 1] - y);
    }
    this._updateObject(this.activeObject.uuid, { x, y, points: newPoints, isLocked: false });
    this.selectObject(null);
  }

  get transformerDestinationElement() {
    return document.querySelector('.konvajs-content');
  }

  get textEditorDestinationElement() {
    return document.querySelector('#canvas-container');
  }

  @action
  async onUploadFile(file) {
    this.trackMixpanelEvent('whiteboard image inserted', { 'file_type': file.type });
    if (file.type === FILE_TYPES.pdf) {
      let pdfDocument = await this.whiteboardService.getPDFDocumentData(file);

      if (pdfDocument.numPages === 1) {
        let fileBase64 = await this.whiteboardService.getPDFPageAsDataURL(pdfDocument, 1);

        return this.processUploadedImage(fileBase64, FILE_TYPES.png);
      }

      [...new Array(pdfDocument.numPages)].forEach(() => this.pdfPagesPreviews.push(''));
      await Promise.all(
        this.pdfPagesPreviews.map((_, index) =>
          this.whiteboardService
            .getPDFPageAsDataURL(pdfDocument, index + 1)
            .then(imageData => this.pdfPagesPreviews.splice(index, 1, imageData))
            .catch(this.errorHandling.notifyError)
        )
      );
    } else if (file.type.includes('image/')) {
      let fileBase64 = await blobToBase64(file);

      this.processUploadedImage(fileBase64, file.type);
    } else {
      information({ title: 'File type is not supported', text: 'Please upload JPEG, PNG, or PDF' });
    }
  }

  @action
  async processUploadedImage(fileBase64, fileType) {
    let fileId = generateUUID();

    let { actionData } = await this._createObject({
      fileData: { fileBase64, fileId },
      tool: WHITEBOARD_TOOLS.image,
    });
    setProperties(this, { tool: WHITEBOARD_TOOLS.select });
    this.sendMessage({ fileBase64, fileId, fileType }, DATA_TRACK_MESSAGE_TYPES.file);
    this.sendMessage(
      {
        fileId,
        handlerPayload: {
          message: { eventType: EVENT_TYPES.create, tool: WHITEBOARD_TOOLS.image, actionData },
          type: MESSAGE_TYPE,
        },
      },
      DATA_TRACK_MESSAGE_TYPES.fileHandler
    );
  }

  @action
  selectObject(object) {
    if (object?.isLocked) {
      return;
    }
    if (isText(object) && this.isTextTool) {
      this._updateObject(object.uuid, { isLocked: true });
    }

    this.whiteboardService.setActiveObject(object);
  }

  @action
  deleteObject(object) {
    this._updateObject(object.uuid, { isLocked: false, visible: false });
    this.selectObject(null);
  }

  @action
  _onTwilioParticipantConnected(participant) {
    this.messagingService.handleRemoteReceivingReady(
      participant,
      this._shareWhiteboardToNewParticipant
    );
  }

  async _initWhiteboardFromJSON() {
    if (!this.whiteboardService.initialState) return;

    let optionsList = await this.whiteboardService.getInitialOptionsList();

    optionsList.forEach(options =>
      this._createObject(options, { local: false, selectElement: false })
    );
  }

  @action
  _shareWhiteboardToNewParticipant() {
    if (!this.isSharing) return;

    let fileBase64 = getWhiteboardJSON(this.layer);
    let fileId = generateUUID();

    this.sendMessage(
      { fileBase64, fileId, fileType: FILE_TYPES.json },
      DATA_TRACK_MESSAGE_TYPES.file
    );
    this.sendMessage(
      {
        handlerPayload: {
          message: {
            type: GLOBAL_EVENT_TYPES.startWhiteboard,
            isWhiteboardLocked: this.isWhiteboardLocked,
          },
          type: GLOBAL_EVENT,
        },
        fileId,
      },
      DATA_TRACK_MESSAGE_TYPES.fileHandler
    );
  }

  @action
  async resetWhiteboard() {
    let { dismiss } = await destruction({
      title: 'Are you sure you want to clear the board?',
      text: 'When you clear the Whiteboard, any unsaved changes will be lost.',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Clear',
    });

    if (dismiss) return;
    this.sendMessage({ eventType: EVENT_TYPES.clear });
    this._resetWhiteboard();
    this.trackMixpanelEvent('whiteboard cleared');
  }

  _resetWhiteboard() {
    this.layer.destroyChildren();
    this.whiteboardService.cleanupWhiteboard();
    this.whiteboardService.addBackgroundToLayer(this.layer);
    this.selectObject(null);
  }

  @action
  _handleTextToolClick(event) {
    if (isText(this.activeObject)) {
      return;
    }

    let object = this.objects.get(event.target.attrs.uuid);

    if (isText(object)) {
      this.selectObject(object);
    } else {
      this.createText();
    }
  }

  @action
  _handleSelectToolClick({ evt, target }) {
    if (evt.target.closest(`#${this.transformerId}`)) return;

    let object = this.objects.get(target.attrs.uuid);

    if (!object) {
      this.selectObject(null);
      return;
    }

    if (object.type === WHITEBOARD_TOOLS.text && !object.isLocked && isDoubleClick(evt)) {
      this.onSelectTool(WHITEBOARD_TOOLS.text);
    }

    this.selectObject(object);
  }

  @action
  async _handlePreviewableToolTap({ type }) {
    if (type !== 'tap') return;
    let objectPreview = await this._placePreviewObject();
    await this._createPreviewableObject(objectPreview.konvaObject.getAttrs());
    this._removeObjectPreview(objectPreview, type);
  }

  @action
  handleTextChange(object, text, options = {}) {
    this._updateObject(object.uuid, { text, ...options }, true);
  }

  @action
  closePdfPreviewModal() {
    this.selectedPreviewIndex = 0;
    this.pdfPagesPreviews.splice(0, this.pdfPagesPreviews.length);
  }

  _trackToolUsage() {
    if (this.toolTracked || !this.tool) return;
    let options = { 'tool_type': this.tool };

    if (this.isPenTool) {
      options.thickness = Object.keys(PEN_SIZE_OPTIONS).find(
        key => PEN_SIZE_OPTIONS[key] === this.penSizeOption
      );
      options.color = Object.keys(WHITEBOARD_COLORS).find(
        key => WHITEBOARD_COLORS[key] === this.drawColor
      );
    } else if (this.isShapesTool || this.isStickersTool) {
      // eslint-disable-next-line camelcase
      options.tool_value = this.isShapesTool ? this.shape : this.sticker;
    }
    this.trackMixpanelEvent('whiteboard tool used', options);
    set(this, 'toolTracked', true);
  }

  @action
  trackMixpanelEvent(text, options) {
    this.mixpanel.track(text, options);
  }

  _addMouseEnterListener(stage) {
    stage.on('mouseenter', () => {
      stage.container().style.cursor = getCursorStyle(this.tool);
      this.processPreviewableTool();
    });
  }

  @action
  async _createPreviewableObject(attributes, withEasterEgg) {
    let arrangedCoordinates = fitInStage(attributes);
    let options = {
      ...arrangedCoordinates,
      uuid: generateUUID(),
      tool: this.tool,
      ...this._getPreviewableAttributes(withEasterEgg),
    };

    await this._createObject(options, { sync: true });
  }

  @action
  _removeObjectPreview(objectPreview, eventType = 'click') {
    if (!this.isStickersTool || eventType === 'tap') objectPreview?.konvaObject.destroy();
    if (this.isStickersTool) return;

    this.stage.off(`.${PREVIEWABLE_NAMESPACE}`);
    setProperties(this, { tool: WHITEBOARD_TOOLS.select, objectPreview: null });
    this.stage.container().style.cursor = 'auto';
  }

  @action
  _placePreview(shapePreview) {
    let { x, y } = getPointerPosition(this.stage);
    let { width, height } = shapePreview.konvaObject.size();

    shapePreview.update({ x: x - width / 2, y: y - height / 2 });
  }

  @action
  deselectPreviewableTool({ target }) {
    let skipTarget = [this.canvasContainerId, this.toolbarId].some(selector =>
      target.closest(`#${selector}`)
    );

    if (this.isPenTool || skipTarget) {
      return;
    }
    if (PREVIEWABLE_TOOLS.includes(this.tool)) {
      this.onSelectTool(WHITEBOARD_TOOLS.select);
    }
  }

  async _placePreviewObject() {
    let objectPreview = await this.whiteboardService.getBoardObject({
      tool: this.tool,
      previewMode: true,
      ...this._getPreviewableAttributes(),
    });

    this.objectPreview?.konvaObject.destroy();
    objectPreview.addToLayer(this.layer);
    this._placePreview(objectPreview);
    return objectPreview;
  }

  async processPreviewableTool() {
    if (!PREVIEWABLE_TOOLS.includes(this.tool)) return;

    let objectPreview = await this._placePreviewObject();
    this.stage.on(`mousemove.${PREVIEWABLE_NAMESPACE}`, () => this._placePreview(objectPreview));
    this.stage.on(`mouseleave.${PREVIEWABLE_NAMESPACE}`, () => {
      objectPreview.konvaObject.destroy();
      this.stage.off(`.${PREVIEWABLE_NAMESPACE}`);
      set(this, 'objectPreview', null);
    });
    this._attachPreviewableClickListeners();
    set(this, 'objectPreview', objectPreview);
  }

  _getPreviewableAttributes(withEasterEgg) {
    let attributes;

    if (this.isVanishingPenTool) {
      attributes = { points: [0, 0] };
    } else if (this.isDrawTool) {
      attributes = {
        points: [0, 0],
        stroke: this.drawColor,
        strokeWidth: this.penSizeOption.size,
        opacity: this.penSizeOption.opacity,
      };
    } else if (this.isShapesTool) {
      attributes = { shape: this.shape };
    } else if (!withEasterEgg) {
      attributes = { sticker: this.sticker };
    } else {
      attributes = { sticker: STICKERS_CONFIG[this.sticker].secretSticker || this.sticker };
    }

    return attributes;
  }

  _attachPreviewableClickListeners() {
    if (this.isPenTool) {
      this.stage.on(`mousedown.${PREVIEWABLE_NAMESPACE} touchstart.${PREVIEWABLE_NAMESPACE}`, () =>
        this.objectPreview?.konvaObject.setAttrs({ previewMode: false })
      );
      this.stage.on(`mouseup.${PREVIEWABLE_NAMESPACE} touchend.${PREVIEWABLE_NAMESPACE}`, () =>
        this.objectPreview?.konvaObject.setAttrs({ previewMode: true })
      );
    } else {
      this.stage.on(`click.${PREVIEWABLE_NAMESPACE}`, async ({ evt }) => {
        await this._createPreviewableObject(
          this.objectPreview.konvaObject.getAttrs(),
          !!evt?.shiftKey
        );
        this._removeObjectPreview(this.objectPreview);
      });
    }
  }

  @action
  onTransformationEnd() {
    if (!this.activeObject) return;

    let { rotation, width, height, x, y, points } = this.activeObject.konvaObject.getAttrs();
    let newAttributes = { rotation, width, height, x, y, points };

    this._updateObject(this.activeObject.uuid, { ...newAttributes, isLocked: false }, true);
  }

  @action
  lockActiveObject() {
    this._updateObject(this.activeObject.uuid, { isLocked: true });
  }

  @action
  onHistoryMove(action, { undo, redo }) {
    if (!action) return;

    let object = this.objects.get(action.objectId);

    if (!object || object.isLocked) return;

    this.sendMessage({
      eventType: EVENT_TYPES.update,
      objectId: action.objectId,
      actionData: { ...action, redo, undo },
      ...object.konvaObject.getAttrs(),
    });

    this.trackMixpanelEvent(`whiteboard ${undo ? 'undo' : 'redo'}`);
    if (undo) {
      this.whiteboardService.undoAction(action.id, action.objectId);
    } else {
      this.whiteboardService.redoAction(action.id, action.objectId);
    }
  }

  #subscribeToNewAttendees() {
    if (this.featureThChime) {
      this.rosterService.subscribeToRosterChanges(this._onChimeRosterChange);
    } else {
      this.twilioRoom.room.on(ROOM_EVENTS.participantConnected, this._onTwilioParticipantConnected);
    }
  }

  @action
  _onChimeRosterChange({ isPresent }) {
    if (!isPresent) return;

    this._shareWhiteboardToNewParticipant();
  }

  #shareVideoStream() {
    let videoStream = this.whiteboardService.createVideoStream(this.stage);

    this.audioVideoAdapter.shareWhiteboardVideo(videoStream);
  }
}
