import { template } from "@ember/template-compiler";
import { CUSTOM_ERROR_CODES } from '../../constants/errors';
import { action } from '@ember/object';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import ConnectionErrorModal from 'frontend/components/shared/connection-error-modal';
import MediaSettingsModal from 'frontend/components/call/media-settings-modal';
export default class PagesAppointment extends Component {
    @service
    call;
    @service
    floatingUiElements;
    @service
    router;
    @reads('floatingUiElements.isSettingsModalShown')
    isSettingsModalShown;
    @reads('call.connectionErrorCode')
    connectionErrorCode;
    @action
    resetConnectionError() {
        let errorCode1 = this.connectionErrorCode;
        this.call.setConnectionError(null);
        if (errorCode1 !== CUSTOM_ERROR_CODES.participantsLimitReached) return;
        this.router.transitionTo('appointment');
    }
    static{
        template(`
    {{#if this.connectionErrorCode}}
      <ConnectionErrorModal
        @errorCode={{this.connectionErrorCode}}
        @onClose={{this.resetConnectionError}}
      />
    {{/if}}

    {{#if this.isSettingsModalShown}}
      <MediaSettingsModal />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
