import { template } from "@ember/template-compiler";
import ProfileMenu from 'frontend/components/profile-menu';
import styles from './page-container.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default template(`
  <div class={{styles.component}}>
    <header>
      {{svgJar "telehealth-video" class="header-icon"}}
      {{#if @showProfileMenu}}
        <ProfileMenu @isNameShown={{true}} />
      {{/if}}
    </header>
    <main class="main">{{yield}}</main>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
