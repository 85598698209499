import { JOIN_REQUEST_STATUSES } from 'frontend/constants/session';
import { tracked } from '@glimmer/tracking';

export default class JoinRequest {
  @tracked status = JOIN_REQUEST_STATUSES.waiting;
  @tracked sentToWaitingRoom = false;
  @tracked timestamp = null;

  constructor(data) {
    this.update(data);
    this.timestamp = Date.now();
  }

  update(data) {
    Object.assign(this, data);
  }
}
