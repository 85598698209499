import { LOGS_PATH } from 'frontend/constants';
import Service from '@ember/service';
import log from 'loglevel';
import remote from 'loglevel-plugin-remote';

export default class RemoteLoggerService extends Service {
  #payload = {};

  constructor() {
    super(...arguments);

    let format = ({ level, message }) => {
      let messageObject;

      try {
        messageObject = JSON.parse(message);
      } catch (e) {
        // noop
      }

      return {
        level: level.label,
        source: 'web',
        ...(messageObject ? messageObject : { message }),
        ...this.#payload,
      };
    };
    this.logger = log.noConflict();
    this.remotePlugin = remote;

    this.remotePlugin.apply(this.logger, { url: LOGS_PATH, format });
  }

  info() {
    this.logger.info(...arguments);
  }

  warn() {
    this.logger.warn(...arguments);
  }

  setPayload(payload) {
    this.#payload = payload;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.remotePlugin.disable();
  }
}
