import {
  DEVICES_TIMEOUT_ERROR,
  EMPTY_DEVICES_LIST_ERROR,
  GET_USER_MEDIA_ERROR,
  PERMISSION_DENIED_ERROR,
} from 'frontend/constants/errors';
import { createElement } from 'frontend/utils/banners';
import { information } from 'frontend/utils/modals';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { routeFor } from 'frontend/helpers/route-for';
import Service, { service } from '@ember/service';

const NOT_ALLOWED_ERROR = 'NotAllowedError';
const NOT_FOUND_ERROR = 'NotFoundError';
const NOT_READABLE_ERROR = 'NotReadableError';
const DEVICE_ERROR_MESSAGE =
  "The browser couldn't capture audio and video with your microphone and camera, probably because another app or tab has reserved them.<pre></pre>Try closing any apps or tabs that may be reserving the microphone and camera, then refresh this page. If that doesn't work, restart your browser";
const SESSION_DID_NOT_LOAD_PROPERLY = 'Your Telehealth session did not load properly';

export default class ErrorHandlingService extends Service {
  @service mixpanel;
  @service router;

  notifyError(err) {
    if (err) {
      window._bugsnagClient?.notify(err);
    }
  }

  leaveBreadcrumb(message, metadata) {
    window._bugsnagClient?.leaveBreadcrumb(message, metadata);
  }

  systemPermissionsError() {
    information({
      title: 'Update your settings to share your screen',
      html: createElement('p', {
        innerHTML:
          'To start sharing your entire screen or application window, ' +
          'first update the Screen Recording settings for this browser in your ' +
          '<b>System Preferences</b>.',
      }),
      confirmButtonText: 'Dismiss',
    });
  }

  async joinedFromAnotherDeviceError() {
    await information({
      title: "You're already in this appointment",
      text: 'This appointment was started in another browser tab. Close this tab and use the tab with the active appointment.',
      customClass: {
        confirmButton: 'button primary',
      },
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      showCloseButton: false,
    });
  }

  async roomDisconnectedError() {
    await information({
      title: 'Refresh your session',
      text: `${SESSION_DID_NOT_LOAD_PROPERLY}. Refreshing should resolve the issue.`,
      customClass: {
        confirmButton: 'button primary',
      },
      confirmButtonText: 'Refresh',
      allowOutsideClick: false,
      showCloseButton: false,
    });

    if (isEmberTesting()) {
      return;
    }

    location.reload();
  }

  async roomChannelSubscriptionFailedError(options) {
    let { isTimeout } = options;

    this.notifyError(new Error(`Room channel subscription ${isTimeout ? 'timeout' : 'rejected'}`));
    await information({
      title: 'Connection failed',
      html: createElement('p', {
        innerHTML:
          `${SESSION_DID_NOT_LOAD_PROPERLY}.` +
          '<br> If the issue persists, we recommend ' +
          `<a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.simplepracticeclient.com/hc/en-us/articles/10362444698509-Troubleshooting-Telehealth-appointments"
          >following these troubleshooting steps</a>.` +
          '<br>VPN or antivirus software may also be affecting the Telehealth connection',
      }),
      customClass: {
        confirmButton: 'button primary',
      },
      confirmButtonText: 'Try Again',
      allowOutsideClick: false,
      showCloseButton: false,
    });

    this.router.transitionTo('appointment');
  }

  async connectionLostError() {
    await information({
      title: 'Connection lost',
      text: ' ',
      html: createElement('div', {
        innerHTML:
          'There may be a problem with your internet. Check your internet connection and try again. ' +
          '<a href="https://support.simplepractice.com/hc/en-us/articles/360041146032-Troubleshooting-Telehealth-appointment-issues#additional" target="_blank">Learn more</a>' +
          '<h4>Recommendations</h4>' +
          '<ul>' +
          '<li>If you’re using Wi-Fi, try moving closer to your router</li>' +
          '<li>If available, connect to the internet using an ethernet cable</li>' +
          '<li>Close other programs or browser tabs that you don’t need</li>' +
          '</ul>',
      }),
      customClass: {
        confirmButton: 'button primary',
      },
      confirmButtonText: 'Try again',
      allowOutsideClick: false,
      showCloseButton: false,
    });

    if (isEmberTesting()) {
      return;
    }

    location.reload();
  }

  async getDevicesError(error, hasVideo = false, hasAudio = false) {
    let headline = '';
    let message = '';
    let url = '';
    let title = "We can't get access to your media";
    let confirmButtonText = 'Try again';
    let showCloseButton = false;

    switch (true) {
      // This error is emitted when the user or the user's system has denied permission to use the media devices
      case error?.name === NOT_ALLOWED_ERROR:
      case error?.name === PERMISSION_DENIED_ERROR:
        headline = "Can't access your media:";
        url = routeFor(['support_article_url', '360041146032']);
        if (error?.message === 'Permission denied by system') {
          // Chrome only
          message =
            'The operating system has blocked the browser from accessing the microphone or camera. Please check your operating system settings.';
        } else {
          message =
            'Telehealth requires access to your microphone and camera before you can join a video appointment. To allow access, open <b>System Preferences</b>.';
        }
        message += ` <a href="${url}">Learn more</a>`;
        break;

      case error?.name === NOT_READABLE_ERROR: // Devices are reserved by other app like Zoom, Skype etc. (Windows only)
      case error?.name === DEVICES_TIMEOUT_ERROR: // Input devices couldn't start after timeout
      case error?.message === EMPTY_DEVICES_LIST_ERROR: // Empty list of input devices is returned by the browser
      case error?.name === NOT_FOUND_ERROR: // Input devices are not connected or disabled in the OS settings
      case error?.name === GET_USER_MEDIA_ERROR: // Default chime error for devices
        title = "Can't access your microphone or camera";
        headline = '';
        message = `${DEVICE_ERROR_MESSAGE} or computer.`;
        confirmButtonText = 'Refresh';
        break;

      // Other getUserMedia errors are less likely to happen in this app. Here we will display
      // the system's error message directly to the user.
      case Boolean(error):
        headline = 'Error Acquiring Media:';
        message = `${error?.name} ${error?.message}`;
        break;

      case !hasAudio && !hasVideo:
        headline = "Can't detect camera or microphone:";
        message = 'Other participants in the room will be unable to see and hear you.';
        showCloseButton = true;
        break;

      case !hasVideo:
        headline = "Can't detect camera:";
        message = 'Other participants in the room will be unable to see you.';
        showCloseButton = true;
        break;

      case !hasAudio:
        headline = "Can't detect microphone:";
        message = 'Other participants in the room will be unable to hear you.';
        showCloseButton = true;
    }

    this.notifyError(error);

    return await information({
      title,
      html: createElement('p', {
        innerHTML: `<strong>${headline}</strong>` + `<p>${message}</p>`,
      }),
      customClass: {
        confirmButton: 'button primary',
      },
      confirmButtonText,
      showCloseButton,
      allowOutsideClick: false,
    }).then(result => {
      if ((this.isGetDevicesError(error) || Boolean(error)) && result.isConfirmed)
        location.reload();
    });
  }

  isGetDevicesError(error) {
    return [
      PERMISSION_DENIED_ERROR,
      NOT_ALLOWED_ERROR,
      NOT_FOUND_ERROR,
      NOT_READABLE_ERROR,
      DEVICES_TIMEOUT_ERROR,
      GET_USER_MEDIA_ERROR,
    ].includes(error?.name);
  }

  async connectRoomError(error, { token, sessionId } = {}) {
    this.mixpanel.track('error: Telehealth welcome screen', {
      token,
      sessionId,
      error,
      skipPrefix: true,
    });
    this.notifyError(error);

    return information({
      title: "We can't connect to the room",
      text: 'Please, check your internet connection and try again.',
      confirmButtonText: 'Try again',
      allowOutsideClick: false,
    });
  }
}
