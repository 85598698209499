import { template } from "@ember/template-compiler";
import { JOIN_REQUEST_STATUSES } from 'frontend/constants/session';
import { eq } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import AttachTooltip from 'ember-attacher/components/attach-tooltip';
import SharedModal from 'frontend/components/shared/modal';
import styles from './detailed-view.module.scss';
export default template(`
  <div class={{styles.component}}>
    <SharedModal @close={{@close}} data-test-detailed-join-request as |modal|>
      <modal.header>
        {{@title}}
      </modal.header>
      <div class="content">
        {{#each @requests as |request|}}
          <div class="request" data-test-requester-container={{request.name}}>
            <label class="requester-name">
              {{request.name}}
            </label>
            {{#if (eq request.status JOIN_REQUEST_STATUSES.waiting)}}
              <button
                class="button negative"
                type="button"
                {{on "click" (fn @onDecline request)}}
                data-test-decline-button
              >
                Decline
              </button>
              <button
                class="button tertiary"
                type="button"
                {{on "click" (fn @onAdmit request)}}
                disabled={{@isAdmitDisabled}}
                data-test-admit-button
              >
                Admit
                {{#if @isAdmitDisabled}}
                  <AttachTooltip @placement="bottom">
                    {{@disabledTooltip}}
                  </AttachTooltip>
                {{/if}}
              </button>
            {{else}}
              <span class="status">
                {{request.status}}
              </span>
            {{/if}}
          </div>
        {{/each}}
      </div>

      <modal.footer class="footer-buttons">
        <button
          type="button"
          class="primary button"
          disabled={{@isAdmitDisabled}}
          {{on "click" @onCtaClick}}
          data-test-detailed-view-cta
        >
          {{@ctaLabel}}
          {{#if @isAdmitDisabled}}
            <AttachTooltip @placement="bottom">
              {{@disabledTooltip}}
            </AttachTooltip>
          {{/if}}
        </button>
      </modal.footer>
    </SharedModal>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
