import { headers } from 'frontend/utils/api/fetch-data';

export function requestRecordingStart({ meetingId }) {
  let init = {
    method: 'POST',
    body: JSON.stringify({ meetingId }),
    headers: {
      ...headers(),
      'Content-Type': 'application/json',
    },
  };

  return fetch('/frontend/recordings', init);
}

export function requestRecordingStop({ meetingId }) {
  let init = {
    method: 'PATCH',
    body: JSON.stringify({ meetingId, stop: true }),
    headers: {
      ...headers(),
      'Content-Type': 'application/json',
    },
  };
  let url = `/frontend/recordings/${meetingId}`;

  return fetch(url, init);
}
