export const TRACK_TYPE = {
  audio: 'AUDIO',
  camera: 'CAMERA',
  screensharing: 'SCREENSHARING',
  screenAudio: 'SCREEN_AUDIO',
  data: 'DATA',
};

export const VIDEO_QUALITY_TYPES = {
  auto: 'auto',
  ld: 'ld',
  sd: 'sd',
  hd: 'hd',
};

const GENERAL_CONSTRAINT_OPTIONS = {
  frameRate: 24,
  aspectRatio: 1.777777777,
};

export const LD_VIDEO_CONSTRAINTS = {
  width: 426,
  height: 240,
};

export const SD_WIDE_VIDEO_CONSTRAINTS = {
  width: { exact: 640 },
  height: { exact: 360 },
  ...GENERAL_CONSTRAINT_OPTIONS,
};

export const SD_VIDEO_CONSTRAINTS = {
  width: { exact: 640 },
  height: { exact: 480 },
};

export const HD_VIDEO_CONSTRAINTS = {
  width: { exact: 1280 },
  height: { exact: 720 },
  ...GENERAL_CONSTRAINT_OPTIONS,
};

export const SCREEN_CONSTRAINTS = {
  audio: {
    echoCancellation: true,
    noiseSuppression: true,
    sampleRate: 44100,
  },
  video: {
    frameRate: 10,
    height: 1080,
    width: 1920,
  },
};

export const DATA_TRACK_CONSTRAINTS = {
  maxPacketLifeTime: null,
  maxRetransmits: 100,
};

export const DEFAULT_ZOOM_COEFFICIENT = 0.085;

// NOTE: sync version with app/controllers/application_controller.rb
export const LATEST_API_VERSION = '2024-04-08';

export const LOGS_PATH = '/frontend/logs';
export const SETTINGS_PATH = '/frontend/appointment_settings';

export const SHAREABLE_ENTITIES = {
  screen: 'screen',
  whiteboard: 'whiteboard',
};

export const GLOBAL_EVENT = 'GLOBAL_EVENT';
export const GLOBAL_EVENT_TYPES = {
  startWhiteboard: 'startWhiteboard',
  stopWhiteboard: 'stopWhiteboard',
  unlockWhiteboard: 'unlockWhiteboard',
  lockWhiteboard: 'lockWhiteboard',
  collectClientConsent: 'collectClientConsent',
};
export const DATA_TRACK_MESSAGE_TYPES = {
  file: 'file',
  fileHandler: 'fileHandler',
  pingParticipant: 'pingParticipant',
  responseToPing: 'responseToPing',
};

export const ROOM_CHANNEL_MESSAGE_TYPES = {
  session: 'session',
  acknowledge: 'acknowledge',
};

export const SESSION_EVENTS_TYPES = {
  ended: 'ended',
  shareUserData: 'shareUserData',
  joinRequest: 'joinRequest',
  admitJoin: 'admitJoin',
  declineJoin: 'declineJoin',
  sendToWaitingRoom: 'sendToWaitingRoom',
  participantUnsubscribed: 'participantUnsubscribed',
  hostControlsChanged: 'hostControlsChanged',
  trackSubscriptionConfirmed: 'trackSubscriptionConfirmed',
};

export const TWILIO_CONNECTION_OPTIONS = {
  audio: true,
  video: { height: 576, frameRate: 24, width: 1024 },
  bandwidthProfile: {
    video: {
      mode: 'collaboration',
      dominantSpeakerPriority: 'standard',
      clientTrackSwitchOffControl: 'manual',
      contentPreferencesMode: 'auto',
    },
  },
  dominantSpeaker: true,
  maxAudioBitrate: 16000,
  networkQuality: { local: 3, remote: 1 },
};

export const KEY_CODES = {
  backspace: 8,
  tab: 9,
  enter: 13,
  shift: 16,
};

export const CLIENT_ORIGIN = 'client';

export const ROOM_REFERRER = 'room';

export const NOISE_CANCELLATION_VENDOR_OPTIONS = {
  vendor: 'krisp',
  sdkAssetsPath: `${window.location.origin}/noisecancellation/krisp/1.0.0`,
};

export const CHAT_MESSAGE_TYPE = 'chat_text';

export const CLIENT_CONSENT_ACTIONS = {
  request: 'request',
  allowed: 'allowed',
  declined: 'declined',
};

export const DECLINE_REASONS = {
  hostAction: 'hostAction',
  participantsLimitReached: 'limitReached',
};

export const MAX_PARTICIPANTS = 16;
export const TEN_SECONDS_MS = 10_000;
