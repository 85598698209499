import { DATA_TRACK_MESSAGE_TYPES } from 'frontend/constants';
import { getChunks } from 'frontend/utils/files';
import { reads } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';
import generateUUID from 'ember-simplepractice/utils/generate-uuid';
import moment from 'moment-timezone';

@classic
export default class MessagingService extends Service {
  @service persistentProperties;

  messageHandlers = {};
  chunks = {};
  files = {};
  fileHandlers = {};

  @reads('persistentProperties.userName') participantName;
  @reads('persistentProperties.uuid') participantSid;

  createMessage(message, type, options = {}) {
    return {
      uuid: generateUUID(),
      participantName: this.participantName,
      participantSid: this.participantSid,
      timestamp: moment().format(),
      type,
      message,
      ...options,
    };
  }

  addMessageHandler(type, handler) {
    this.messageHandlers[type] = handler;
  }

  removeMessageHandler(type) {
    delete this.messageHandlers[type];
  }

  receiveMessage(messageObject) {
    let { messageId, type } = messageObject;

    if (!type && messageId) return this.#handleMessageChunk(messageObject);

    switch (type) {
      case DATA_TRACK_MESSAGE_TYPES.file:
        return this.#handleFileReceiving(messageObject);
      case DATA_TRACK_MESSAGE_TYPES.fileHandler:
        return this.#addFileHandlerPayload(messageObject);
      default:
        return this.messageHandlers[messageObject.type]?.(messageObject);
    }
  }

  sendMessage(message, type, options) {
    let messageObject = this.createMessage(message, type, options);
    let messageString = JSON.stringify(messageObject);
    let messageSize = new Blob([messageString]).size;

    if (this.maxBytesAllowed < messageSize) {
      let messageId = generateUUID().substring(0, 8);

      getChunks(messageString, this.maxBytesAllowed).forEach((chunk, index, chunks) => {
        this.send({ messageId, total: chunks.length, index, chunk });
      });
    } else {
      this.send(messageObject);
    }

    return messageObject;
  }

  send() {
    throw new Error('The method should be implemented');
  }

  #handleFileReceiving({ message }) {
    let { fileId, fileBase64, fileType } = message;

    this.files[fileId] = { fileBase64, fileType };
    return this.#ensureFileLoaded(fileId);
  }

  #addFileHandlerPayload(messageObject) {
    let { handlerPayload, fileId } = messageObject.message;
    let { type, message } = handlerPayload;

    this.fileHandlers[fileId] = fileData =>
      this.messageHandlers[type]?.({ ...messageObject, message: { ...message, fileData } });
    return this.#ensureFileLoaded(fileId);
  }

  async #ensureFileLoaded(fileId) {
    if (!this.files[fileId] || !this.fileHandlers[fileId]) return;

    let { fileBase64, fileType } = this.files[fileId];

    await this.fileHandlers[fileId]({ fileBase64, fileType, fileId });
    delete this.files[fileId];
    delete this.fileHandlers[fileId];
  }

  #handleMessageChunk(messageObject) {
    let { messageId, chunk, index, total } = messageObject;
    let message = { chunkData: chunk, chunkIndex: index };

    this.chunks[messageId] = {
      chunks: this.chunks[messageId] ? [...this.chunks[messageId].chunks, message] : [message],
      totalChunks: total,
    };
    this.#ensureMessageLoaded(messageId);
  }

  #ensureMessageLoaded(messageId) {
    if (!this.chunks[messageId]) return;

    let { chunks, totalChunks } = this.chunks[messageId];
    if (chunks.length !== totalChunks) return;

    let message = this.chunks[messageId].chunks
      .sort((a, b) => a.chunkIndex - b.chunkIndex)
      .map(p => p.chunkData)
      .join('');

    this.receiveMessage(JSON.parse(message));
    delete this.chunks[messageId];
  }
}
